import React from 'react';

import { Wrapper, Icon } from './styles';

export interface BackButtonProps {
  className?: string;
  onClick?: () => void;
  theme?: ThemeOptions;
  themeMobile?: ThemeOptions;
  children?: React.ReactNode;
}

const Back: React.FC<BackButtonProps> = ({
  className = '',
  onClick,
  theme = 'black',
  themeMobile = 'black',
  children,
}) => {
  return (
    <Wrapper className={className} onClick={onClick} type="button">
      <Icon theme={theme} themeMobile={themeMobile} viewBox="0 0 25 25">
        <g transform="translate(-15 -55)">
          <rect width="25" height="25" transform="translate(15 55)" />
          <path
            d="M258.368-2195.153l-7.006-7a.937.937,0,0,1-.07-.064,1,1,0,0,1,0-1.415l7.071-7.071a1,1,0,0,1,.707-.292,1,1,0,0,1,.706.292,1,1,0,0,1,0,1.415L254.486-2204H268a1,1,0,0,1,1,1,1,1,0,0,1-1,1h-13.65l5.433,5.433a1,1,0,0,1,0,1.414,1,1,0,0,1-.707.293A1,1,0,0,1,258.368-2195.153Z"
            transform="translate(-233 2270)"
          />
        </g>
      </Icon>
      {children}
    </Wrapper>
  );
};

export default Back;
