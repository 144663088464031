import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import colors from '../../../constants/colors';

import icClose from '../../../assets/icons/ic_close_white.svg';
import logo from '../../../assets/icons/logo_white.svg';

// import ilCompletedSession from '../../../assets/icons/il_completed_session.svg';

import { Background } from '../styles';
import BadgeGrey from '../../Badges/styles';

export const BackgroundStyled = styled(Background)``;

export const Logo = styled.div`
  background-image: url("${logo}");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 50px;
  position: relative;
  z-index: 10001;
`;

export const Header = styled.div`
  ${props =>
    props.image
      ? css`
          background-image: url("${props.image}");
        `
      : css`
          background-color: ${rgba(colors.main.lightBlue, 0.7)};
        `}

  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2px;
  position: relative;
  margin-bottom: 10px;
  height: 200px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: 10;

    background: linear-gradient(
      45deg,
      ${rgba(colors.main.ruby, 0.7)} 16%,
      ${rgba(colors.main.blue, 0.7)} 64%
    );
  }

  @media screen and (max-width: 992px) {
    outline: 10px solid ${colors.main.white};
    height: 30vh;
    padding: 1px 0;
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    height: 350px;
  }

  @media screen and (min-width: 765px) and (max-width: 992px) and (orientation: portrait) {
    height: 350px;
  }
`;

export const NextSession = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto 0;
  justify-content: center;
  border-top: 1px solid ${colors.main.dividers};
  border-bottom: 1px solid ${colors.main.dividers};
  align-items: center;
  padding: 8px 0;
  width: 255px;
`;

export const NextSessionTitle = styled.div`
  width: 100%;
`;

export const Description = styled.div`
  white-space: pre-line;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const ModalWrapper = styled.div`
  display: flex;
  width: 100vw;
  max-width: 720px;
  padding: 20px 0;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  max-height: 100%;

  @media screen and (max-width: 992px) {
    margin: 0;
    padding: 0;
  }
`;

export const Modal = styled.div`
  background-color: ${colors.main.white};
  width: 100%;
  border-radius: 2px;
  opacity: 0.99;
  animation: fadein 1s;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;
  margin: auto 0;

  @media screen and (max-width: 992px) {
    animation: easein 1s;
    height: auto;
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    85% {
      opacity: 0.8;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes easein {
    from {
      transform: translate(-50%, 140%);
    }
    to {
      transform: translate(-50%, -50%);
    }
  }
`;

export const CloseIcon = styled.div`
  width: 25px;
  height: 25px;
  background: url(${icClose});
  cursor: pointer;
  z-index: 10001;
`;

export const HeaderTop = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 20;
`;

export const HeaderTitle = styled.div`
  position: relative;
  padding: 20px;
  z-index: 100;

  @media screen and (max-width: 992px) {
    left: 10px;
    bottom: 10px;
  }
`;

export const Date = styled.span`
  color: ${colors.main.grey};
`;

export const Content = styled.div`
  padding: 20px;

  @media screen and (max-width: 992px) {
    padding: 10px;
  }
`;

export const NextSessionLinkGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 24px;
  width: 100%;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
`;

export const SessionDetails = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  align-items: stretch;
`;

export const SessionDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-shrink: 0;
  align-items: center;
  width: 200px;
`;

export const SessionDetailValue = styled.div`
  text-align: center;
`;

export const SessionDetailData = styled.div`
  text-align: center;
`;

export const Badge = styled(BadgeGrey)`
  float: right;
  margin: 0;
`;
