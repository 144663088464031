import React, { memo } from 'react';
import styled from 'styled-components';
import linkIcon from '../../assets/icons/link.svg';

const StyledLinkIcon = styled.i`
  background: url(${linkIcon}) 0 0 no-repeat;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  top: ${props => (props.isSmall ? '10px' : '15px')};
  right: ${props => (props.isSmall ? '10px' : '15px')};
  width: 30px;
  height: 30px;
`;

const LinkIcon = ({ isSmall }) => <StyledLinkIcon isSmal={isSmall} />;

export default memo(LinkIcon);
