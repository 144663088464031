import styled, { css } from 'styled-components';
import colors from '../../constants/colors';
import { pictograms } from '../../constants';
import BadgeGrey from '../Badges/styles';

import Link from '../Link';

const NoLinkWrapper = styled.div`
  padding: 16px 0;
`;

const Wrapper = styled(Link)`
  padding: 15px 5px 25px;
  &:hover {
    background-color: ${colors.main.textButtonHoverBackground};
  }

  ${props =>
    props.thumb &&
    css`
      padding-left: 8px;

      @media screen and (max-width: 400px) {
        padding: 8px 8px 12px;
      }
    `}
`;
const Header = styled.div`
  position: relative;
  margin-bottom: 7px;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;

  ${props =>
    props.thumb &&
    css`
      @media screen and (max-width: 400px) {
        flex-wrap: wrap;
      }
    `}
`;

const Title = styled.div`
  width: 90%;
`;

const SessionThumb = styled.div`
  background-color: ${colors.main.dividers};
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 15px;
  position: relative;
  height: 80px;
  width: 80px;

  @media screen and (max-width: 400px) {
    width: 100%;
    height: 170px;
    margin-right: 0;
    margin-bottom: 12px;
  }

  &:before {
    content: '';
    background: ${colors.main.lightBlue};
    border-radius: 2px;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    z-index: 1;
  }
`;

const Progress = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  margin-bottom: 2px;
  flex: 1 1 0;
  min-width: 170px;
`;

const Pictogram = styled.div`
  height: 25px;
  width: 25px;
  margin-right: 10px;
  margin-top: 5px;

  ${props =>
    props.icon
      ? css`
          background: url(${pictograms[props.icon]}) center center no-repeat;
        `
      : css`
          background: none;
        `}

  background-size: contain;
`;

const Pictograms = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${props => (props.thumb ? '25px' : '45px')};
  justify-content: ${props => (props.thumb ? 'space-between' : 'flex-end')};
  flex-basis: ${props => (props.thumb ? '100%' : 'auto')};
`;

const DetailsTitle = styled.div`
  width: 100%;
`;

const DetailsValue = styled.div`
  color: ${colors.main.black};
  font-size: 22px;
  font-weight: 700;
  width: 100%;
`;

const Column = styled.div`
  flex-shrink: 0;

  &:nth-of-type(2) {
    margin-right: 10px;
    flex-shrink: unset;
  }

  &:nth-of-type(3) {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
  }

  @media screen and (max-width: 992px) {
    &:nth-of-type(2) {
      width: calc(75% - 40px);
    }
  }
`;

const Badge = styled(BadgeGrey)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  height: auto;
  margin: 0;
  padding: 3px 5px;
`;

export {
  Wrapper,
  NoLinkWrapper,
  Progress,
  Content,
  Column,
  Title,
  Details,
  DetailsTitle,
  DetailsValue,
  Header,
  SessionThumb,
  Pictogram,
  Pictograms,
  Badge,
};
