import * as React from 'react';
import Router from 'next/router';

import getConfig from 'next/config';

import Text from 'components/core/Text';

import { Content, Picture, CloseIcon, TextWrapper, BackgroundStyled, LinkStyled } from './styles';

const { publicRuntimeConfig } = getConfig();
class AlphaPost extends React.Component {
  handleModalClose = () => {
    const { hideModal, notViewedAchievements, showModal } = this.props;
    Router.push('/').then(() => {
      hideModal();
      if (notViewedAchievements && notViewedAchievements.length) {
        showModal('ACHIEVEMENTS');
      }
    });
  };

  goToForum = () => {
    window.open(publicRuntimeConfig.posseLink, '_blank');
  };

  render() {
    return (
      <>
        <BackgroundStyled onClick={this.handleModalClose} />
        <Content>
          <CloseIcon onClick={this.handleModalClose} />
          <Picture />
          <Text variant="sectionHeader" tag="div" align="center" mb={5}>
            WELL DONE!
          </Text>
          <TextWrapper>
            <Text align="center">
              Your message was posted to your forum log! Your&nbsp;GMB coach will review it and
              follow up with&nbsp;you&nbsp;as soon as possible.
            </Text>
          </TextWrapper>
          <TextWrapper>
            <Text align="center">
              Keep an eye on <LinkStyled onClick={this.goToForum}>your forum log</LinkStyled> for a
              response.
            </Text>
          </TextWrapper>
        </Content>
      </>
    );
  }
}

export default AlphaPost;
