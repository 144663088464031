import styled from 'styled-components';
import colors from '../../constants/colors';

// rewrite with text component
const Badge = styled.p`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.08em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 8px;
  height: 25px;
  text-transform: uppercase;
  padding: 5px 10px;
  display: inline-block;

  @media screen and (max-width: 992px) {
    margin-top: 15px;
  }
`;

const BadgeGrey = styled(Badge)`
  color: ${colors.main.white};
  background: ${colors.main.placeholdersAndDisabledBtnsText};
`;

export default BadgeGrey;
