import styled from 'styled-components';
import colors from '../../../constants/colors';
import icCloseBlack from '../../../assets/icons/ic_close_black.svg';
import { Background } from '../styles';

export const BackgroundStyled = Background;

export const CloseIcon = styled.div`
  width: 25px;
  height: 25px;
  background: url(${icCloseBlack});
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 15px 0 0;
  cursor: pointer;
  z-index: 1;
`;

export const Content = styled.div`
  background-color: ${colors.main.white};
  width: 770px;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  opacity: 0.99;
  animation: fadein 1s;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;
  z-index: 10002;

  @media screen and (max-width: 992px) {
    animation: easein 1s;
    height: 100%;
    width: 100%;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    85% {
      opacity: 0.8;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes easein {
    from {
      transform: translate(-50%, 140%);
    }
    to {
      transform: translate(-50%, -50%);
    }
  }
`;

export const Wrapper = styled.div`
  .plyr {
    border-radius: 2px;
    overflow: hidden;
    margin: 0 auto;
    height: 523px;
    width: 100%;

    &--fullscreen-fallback {
      position: fixed !important;
      height: 100% !important;
    }

    video {
      display: block;
      margin: 0 auto;
      height: 100%;
      width: 100%;

      @media screen and (max-width: 992px) {
        height: 100% !important;
      }
    }

    @media screen and (max-width: 992px) {
      height: 211px;
    }

    @media screen and (max-width: 992px) and (orientation: landscape) {
      height: 100%;
    }

    input[type='range'] {
      color: ${colors.main.lightBlue};
    }

    &__control[data-plyr='play'] {
      background: ${colors.main.lightBlue};

      &:hover {
        background: ${colors.main.lightBlue};
      }
    }
  }
`;
