import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import colors from 'constants/colors';
import theme from 'constants/theme';

interface SectionProps extends SpaceProps {
  /**
   * Keep section height to 100% of the viewport on mobiles
   * @default true
   */
  fillViewport?: boolean;
}

const Section = styled.section<SectionProps>`
  ${space};
  background: ${colors.main.white};
  border-radius: 2px;
  position: relative;

  @media screen and (max-width: 992px) {
    min-height: ${({ fillViewport = true }) => (fillViewport ? '100vh' : 'auto')};
  }
`;

Section.defaultProps = { theme };

export default Section;
