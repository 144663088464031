import React, { useEffect, useMemo, useState } from 'react';

import sortPrograms from '../../utils/sortPrograms';
import Programs from './styles';
import FeaturedProgram from './components/FeaturedProgram';
import ProgramsList from './components/ProgramsList';

const PurchasedPrograms = ({
  clearExercisesState,
  programs,
  lastUserWorkoutSessionDate,
  fetchPrograms,
  isResumingSession,
  showModal,
}) => {
  const purchasedPrograms = useMemo(() => programs.all, [programs]);
  const [featuredProgram, setFeaturedProgram] = useState(null);
  const [otherPrograms, setOtherPrograms] = useState([]);

  useEffect(() => {
    if (purchasedPrograms) {
      if (purchasedPrograms?.length > 0) {
        const sortedPrograms = sortPrograms(purchasedPrograms);
        setFeaturedProgram(sortedPrograms[0]);
        setOtherPrograms(sortedPrograms.slice(1));
      }
    }
  }, [purchasedPrograms]);

  if (!featuredProgram) {
    return null;
  }

  return (
    <Programs>
      <FeaturedProgram
        program={featuredProgram}
        clearExercisesState={clearExercisesState}
        lastUserWorkoutSessionDate={lastUserWorkoutSessionDate}
        fetchPrograms={fetchPrograms}
        isResumingSession={isResumingSession}
        showModal={showModal}
      />
      <ProgramsList programs={otherPrograms} clearExercisesState={clearExercisesState} />
    </Programs>
  );
};

export default PurchasedPrograms;
