import styled, { css } from 'styled-components';

import { pictograms } from 'constants/ponder';

interface PonderPictogramProps {
  icon: string;
  /**
   * Size of pictogram
   * Default: 32px;
   */
  size?: number;
}

const PonderPictogram = styled.div<PonderPictogramProps>`
  height: ${props => props.size || 32}px;
  width: ${props => props.size || 32}px;
  margin-right: 10px;
  background-size: contain;

  ${props =>
    props.icon
      ? css`
          background: url(${pictograms[props.icon]}) center center no-repeat;
        `
      : css`
          background: none;
        `}
`;

export default PonderPictogram;
