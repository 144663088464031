import Section from 'components/core/Section';
import colors from 'constants/colors';
import styled from 'styled-components';

import { Close, Content as ModalContent } from '../styles';

export const Content = styled(ModalContent)`
  width: 820px;
  max-width: 100vw;
  position: relative;

  @media screen and (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 32px;
  padding: 8px;
  border-radius: 50%;
  background-color: ${colors.main.white}88;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background-color: ${colors.main.white};
  }
`;

export const ExerciseModalClose = styled(Close)`
  position: relative;
  width: 24px;
  height: 24px;
  top: 0;
  right: 0;
`;

export const ExerciseVideo = styled.video`
  width: 100%;
`;

export const CuesWrapper = styled(Section)`
  border-top: 1px solid ${colors.main.disabledBtns};
`;

export const CuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

export const CuePreview = styled.img`
  width: 100%;
  min-height: 75%;
  object-fit: cover;
  margin-bottom: 8px;
  background-color: ${colors.main.secondaryBtns};
`;
