import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Textarea from 'react-textarea-autosize';
import colors from '../../../../constants/colors';

import Button from '../../../Button';
import Back from '../../../Back';

import infoIcon from '../../../../assets/icons/ic_info_icon.svg';

const Section = styled.section`
  background: ${colors.main.white};
  border-radius: 2px;
  padding: 40px 0;
  position: relative;
  min-height: 608px;

  @media screen and (max-width: 992px) {
    min-height: 100vh;
  }
`;

const PonderButton = styled(Button)`
  width: 314px;
  align-self: center;

  @media screen and (max-width: 992px) {
    box-shadow: 0 -8px 36px ${rgba(colors.main.blue, 0.4)};
    position: fixed;
    bottom: 0;
    border-radius: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
  }
`;

const Description = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  grid-gap: 84px;
  justify-content: start;
  align-items: start;

  @media screen and (max-width: 992px) {
    display: block;
  }
`;

const PonderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

const PonderContent = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: stretch;
  margin-bottom: 20px;
  padding: 0 2px;
  width: 100%;
  flex-wrap: nowrap;

  @media screen and (max-width: 992px) {
    justify-content: space-between;
    flex-direction: column;
  }
`;

const ToolTip = styled.div`
  transform: translate(-100%, 0);
  top: 0;
  background: ${rgba(colors.main.black, 0.6)};
  border-radius: 2px;
  transition: opacity 0.5s;
  position: absolute;
  left: -5px;
  padding: 4px 14px 5px 10px;
  width: 171px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    transform: translate(100%, 150%) rotate(-45deg);
    border: none;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
    border-right: 3px solid ${rgba(colors.main.black, 0.6)};
    border-bottom: 3px solid ${rgba(colors.main.black, 0.6)};
    right: 3px;
    height: 0;
    width: 0;
  }
`;

const Tip = styled.i`
  flex-shrink: 0;
  display: block;
  position: relative;
  cursor: pointer;
  background: url(${infoIcon}) 50% 50% no-repeat;
  background-size: contain;
  height: 25px;
  width: 25px;

  &:hover {
    ${ToolTip} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const PonderInput = styled(Textarea)`
  font-size: 16px;
  font-weight: 400;
  border-top: 1px solid ${colors.main.dividers};
  border-bottom: 1px solid ${colors.main.dividers};
  padding-bottom: 10px;
  width: 100%;
  margin-bottom: 20px;

  &::placeholder {
    color: ${colors.main.placeholdersAndDisabledBtnsText};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.main.placeholdersAndDisabledBtnsText};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.main.placeholdersAndDisabledBtnsText};
  }

  &:focus {
    border-bottom: 1px solid ${colors.main.placeholdersAndDisabledBtnsText};
  }

  &::selection {
    background: #ccc;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Question = styled.div`
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
`;
const Questions = styled.div`
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (min-width: 765px) and (max-width: 992px) and (orientation: portrait) {
    width: 89.333%;
  }

  ${Question} {
    width: 280px;

    @media screen and (max-width: 992px) {
      margin: 0 auto;
    }

    @media screen and (max-width: 992px) and (orientation: portrait) {
      &:first-of-type {
        margin-bottom: 10px;
      }
    }

    @media screen and (min-width: 765px) and (max-width: 992px) and (orientation: portrait) {
      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const RadioExerciseImage = styled.span`
  display: inline-block;
  ${props =>
    props.image
      ? css`
          background-image: url("${props.image}");
        `
      : css`
          background-color: ${colors.main.lightBlue};
        `}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  border-radius: 2px;
  margin-right: 10px;
  vertical-align: middle;
  border: 2px solid transparent;
`;

const RadioExerciseText = styled.span`
  font-size: 110%;
  font-weight: 900;
  display: inline-block;
  padding: 30px 0;
  width: calc(90% - 100px);
`;

const RadioButtonBody = styled.span`
  ${props =>
    props.active &&
    css`
      & ${RadioExerciseImage} {
        border: 2px dashed #333;
      }
    `}

  &:hover ${RadioExerciseImage} {
    border: 2px dashed #333;
  }
`;

// rewrite with text component
const BackButton = styled(Back)`
  display: flex;
  margin: 0 auto 30px auto;
  width: 70px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: ${colors.main.black};
  &:hover {
    color: ${colors.main.blue};
    path {
      fill: ${colors.main.blue} !important;
    }
  }
`;

export {
  Section,
  PonderButton,
  Description,
  PonderInput,
  Tip,
  ToolTip,
  Form,
  Questions,
  PonderHeader,
  PonderContent,
  RadioExerciseImage,
  RadioExerciseText,
  RadioButtonBody,
  BackButton,
};
