import React from 'react';

const SvgAdAlphaPosse = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="10 6 85 85" {...props}>
    <defs>
      <linearGradient
        id="ad_alpha-posse_svg__b"
        x1={0.317}
        y1={0.193}
        x2={0.739}
        y2={0.762}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#dadee3" />
        <stop offset={1} stopColor="#b6bec9" />
      </linearGradient>
      <filter
        id="ad_alpha-posse_svg__a"
        x={0}
        y={0}
        width={105}
        height={111.603}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={7} />
        <feGaussianBlur stdDeviation={10} result="blur" />
        <feFlood floodColor="#6c7888" floodOpacity={0.4} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      data-name="Ellipse 869"
      transform="translate(10 6)"
      fill="none"
      stroke="#dadee3"
      strokeWidth={2}
    >
      <circle cx={42.5} cy={42.5} r={42.5} stroke="none" />
      <circle cx={42.5} cy={42.5} r={41.5} />
    </g>
    <g
      data-name="Ellipse 870"
      transform="translate(15 11)"
      fill="none"
      stroke="#dadee3"
      strokeWidth={2}
    >
      <circle cx={37.5} cy={37.5} r={37.5} stroke="none" />
      <circle cx={37.5} cy={37.5} r={36.5} />
    </g>
    <g filter="url(#ad_alpha-posse_svg__a)">
      <path
        data-name="Path 1531"
        d="M45 38.091V13.512a1.183 1.183 0 0 0-.611-1.039L23.111.183a1.111 1.111 0 0 0-1.223 0L.611 12.473A1.261 1.261 0 0 0 0 13.512v24.579a1.183 1.183 0 0 0 .611 1.039l21.278 12.29a1.111 1.111 0 0 0 1.223 0l21.277-12.29A1.183 1.183 0 0 0 45 38.091z"
        transform="translate(30 23)"
        fill="url(#ad_alpha-posse_svg__b)"
      />
    </g>
    <g data-name="Group 4010">
      <path
        data-name="Path 1534"
        d="M52.5 41.5l1.8 3.6.5.9 1 .1 4 .5-2.8 2.8-.7.7.2 1 .7 3.9-3.5-1.8-.9-.5-.9.5-3.5 1.9.6-3.9.2-1-.8-.7-3.1-2.7 3.9-.6 1-.2.5-.9 1.8-3.6m0-4.5L49 44.2l-8 1.3 5.8 5.5-1.3 8 7.1-3.8 7.1 3.7-1.4-7.9 5.7-5.7-8-1.1-3.5-7.2z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgAdAlphaPosse;
