import { connect } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import { hideModal } from '../../actions/modal';

const mapStateToProps = state => {
  return {
    modalType: state.modal.modalType,
    modalData: state.modal.modalData,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => dispatch(hideModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
