import React, { useState } from 'react';
import Router from 'next/router';
import styled from 'styled-components';
import colors from '../../constants/colors';
import icRestartBlack from '../../assets/icons/ic_restart_black.svg';
import icRestart from '../../assets/icons/ic_restart.svg';
import Button from '../Button';

const Wrapper = styled.div`
  display: flex;

  @media screen and (max-width: 992px) {
    display: grid;
    grid-template-columns: 100% 64px;
    ${props => (props.fullWidthOnMobile ? 'width: 100%;' : '')}
  }
`;

const StyledButton = styled(Button)`
  max-width: ${props => (props.isSmall ? 'unset' : '290px')};
  ${props => (props.isSmall ? 'height: 40px;' : '')}
`;

const ResumeButton = styled(StyledButton)`
  width: ${props => (props.isSmall ? '250px' : '230px')};

  @media screen and (max-width: 992px) {
    ${props => (props.fullWidthOnMobile ? 'width: 100%; max-width: unset;' : '')}
  }
`;

const RestartButton = styled(StyledButton)`
  width: ${props => (props.isSmall ? '40px' : '64px')};
  height: ${props => (props.isSmall ? '40px' : '60px')};
  margin-left: 10px;
  background-color: ${props => (props.isSmall ? colors.main.lightBlue : colors.main.secondaryBtns)};

  @media screen and (max-width: 992px) {
    padding: 0 !important;
  }
`;

const Icon = styled.svg`
  background: url(${props => (props.isSmall || props.isWhite ? icRestart : icRestartBlack)}) 50% 50%
    no-repeat !important;
  width: ${props => (props.isSmall ? '32px' : '50px')};
  height: ${props => (props.isSmall ? '32px' : '50px')};
`;

const ButtonActions = {
  resumeSession: 'ResumeSession',
  startProgram: 'StartProgram',
  restartSession: 'RestartSession',
  startSession: 'StartSession',
};

function getHandlers(props, setState = () => {}) {
  const { program, clearExercisesState = () => {}, handleClose = () => {} } = props;
  const programId = program.id;

  const startProgram = e => {
    e.preventDefault();
    setState({ startClicked: true });
    handleClose?.();
    clearExercisesState?.();
    Router.push(`/programs/${programId}/intro`);
  };

  const startSession = e => {
    e.preventDefault();
    const { nextSession } = program;
    setState({ startClicked: true });
    handleClose?.();
    clearExercisesState?.();
    Router.push(`/programs/${programId}/sessions/${nextSession.id}/start`);
  };

  function resumeSession(e) {
    e.preventDefault();
    const { lastSession } = program;
    setState({ resumeClicked: true });
    handleClose?.();
    Router.push(`/programs/${programId}/sessions/${lastSession.id}/start?resume=true`);
  }

  return {
    startProgram,
    startSession,
    resumeSession,
  };
}

function GetActionButtonMap(props) {
  const [state, setState] = useState({
    startClicked: false,
    resumeClicked: false,
  });
  const handlers = getHandlers(props, setState);

  return {
    [ButtonActions.resumeSession]: (
      <Wrapper fullWidthOnMobile={props.fullWidthOnMobile}>
        <ResumeButton
          handleClick={handlers.resumeSession}
          isLoading={state.resumeClicked}
          disabled={state.startClicked}
          fullWidthOnMobile={props.fullWidthOnMobile}
          isSmall={props.isSmall}
        >
          Resume Session
        </ResumeButton>
        <RestartButton
          secondary={!props.primaryColorForRestartButton}
          handleClick={handlers.startSession}
          disabled={state.resumeClicked}
          isLoading={state.startClicked}
          isSmall={props.isSmall}
        >
          <Icon isSmall={props.isSmall} isWhite={props.primaryColorForRestartButton} />
        </RestartButton>
      </Wrapper>
    ),
    [ButtonActions.startProgram]: (
      <StyledButton
        handleClick={handlers.startProgram}
        isLoading={state.startClicked}
        isSmall={props.isSmall}
      >
        Start Program
      </StyledButton>
    ),
    [ButtonActions.startSession]: (
      <StyledButton
        handleClick={handlers.startSession}
        isLoading={state.startClicked}
        isSmall={props.isSmall}
      >
        Start Session
      </StyledButton>
    ),
    [ButtonActions.restartSession]: (
      <StyledButton
        handleClick={handlers.startSession}
        isLoading={state.startClicked}
        isSmall={props.isSmall}
      >
        Restart Session
      </StyledButton>
    ),
  };
}

function getActionHandlerMap(props) {
  const handlers = getHandlers(props);
  return {
    [ButtonActions.resumeSession]: handlers.resumeSession,
    [ButtonActions.startProgram]: handlers.startProgram,
    [ButtonActions.startSession]: handlers.startSession,
  };
}

function getButtonAction(props) {
  const { program } = props;

  const { lastSession, nextSession, completedSessionsCount } = program;
  const { completedAt: lastSessionCompleted, id: lastSessionId } = lastSession ?? {};
  const { id: nextSessionId } = nextSession ?? {};

  if (lastSession?.workout?.preparationPhaseAnswer === 2) {
    return ButtonActions.restartSession;
  }
  if (!lastSessionCompleted && lastSessionId && nextSessionId && nextSessionId === lastSessionId) {
    return ButtonActions.resumeSession;
  }
  if (!completedSessionsCount) {
    return ButtonActions.startProgram;
  }
  return ButtonActions.startSession;
}

function StartRestartResumeButton(props) {
  const action = getButtonAction(props);
  if (action) {
    return GetActionButtonMap(props)[action];
  }

  return null;
}

export function StartRestartResumeHandler(props) {
  const action = getButtonAction(props);
  if (action) {
    return getActionHandlerMap(props)[action];
  }
}

export default StartRestartResumeButton;
