import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Link from '../../../Link';
import Button from '../../../Button';
import colors from '../../../../constants/colors';
import stretchBuilder from '../../../../utils/animations';

const ProgramImage = styled.img`
  height: 100%;
  width: 100%;
`;

const FeaturedProgram = styled.div`
  flex: 1 1 100%;
  @media screen and (min-width: 550px) and (max-width: 784px) {
    padding-right: 6px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    padding-right: 0;
    border-bottom: 1px solid #f0f0f0;
  }

  ${ProgramImage} {
    filter: brightness(0.8);
    object-fit: cover;
    z-index: 1;
  }
`;

const Program = styled(Link)`
  display: block;
  background: ${colors.main.dividers} 50% 50% no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 395px;

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    position: absolute;
    z-index: 2;
    pointer-events: none;

    ${props =>
      props.sessionDaysCompleted
        ? css`
            animation: ${stretchBuilder(props.sessionDaysCompleted)} 2s ease-in-out;
            animation-fill-mode: forwards;
            background-color: ${rgba(colors.main.lightBlue, 0.7)};
            background: linear-gradient(
              35deg,
              ${rgba(colors.main.ruby, 0.5)} 16%,
              ${rgba(colors.main.blue, 0.5)} 64%
            );
            opacity: 1;
            right: initial;
          `
        : css`
            background: linear-gradient(
              35deg,
              ${rgba(colors.main.ruby, 0.5)} 16%,
              ${rgba(colors.main.blue, 0.5)} 64%
            );
          `}
  }

  @media screen and (max-width: 992px) {
    height: 256px;
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    height: 350px;
  }
`;

const Information = styled.div`
  align-items: flex-end;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 16px;
  bottom: 16px;
  z-index: 100;
  width: 97%;
  padding-right: 12px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 90%;
  }
`;

const InformationDay = styled.div`
  white-space: nowrap;
  width: 100%;
`;

const StyledButton = styled(Button)`
  max-width: 290px;
`;

// rewrite with text component
const InformationTitle = styled.h3`
  transform: translateX(-2px);
  width: 100%;
  white-space: nowrap;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.main.white};
  font-size: 48px;
  font-family: 'DINNextW01-Condensed', Arial, sans-serif;
  font-weight: 300;
  line-height: 50px;
  letter-spacing: 0;
  text-transform: uppercase;
  padding-bottom: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-shadow: 0 0 4px ${colors.main.grey};

  @media screen and (max-width: 550px) {
    white-space: normal;
  }

  @media screen and (max-width: 992px) {
    max-height: 100px;
    overflow: hidden;
  }
`;

export {
  FeaturedProgram,
  ProgramImage,
  Program,
  InformationDay,
  InformationTitle,
  Information,
  StyledButton,
};
