import { connect } from 'react-redux';
import { getPrograms } from 'reducers/programs';
import { getUser, getIsUserCaption } from 'reducers/user';

import FPsFramework from '../../../components/Modal/FPsFramework';

const mapStateToProps = state => {
  return {
    user: getUser(state),
    isUserCaption: getIsUserCaption(state.user),
    program: getPrograms(state),
  };
};

export default connect(mapStateToProps)(FPsFramework);
