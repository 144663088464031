import styled from 'styled-components';
import colors from '../../constants/colors';

const THEME = {
  black: colors.main.black,
  white: colors.main.white,
  grey: colors.main.placeholdersAndDisabledBtnsText,
};

const Wrapper = styled.button`
  background: transparent;
  cursor: pointer;
`;

interface IconProps {
  theme: ThemeOptions;
  themeMobile: ThemeOptions;
}

const Icon = styled.svg<IconProps>`
  height: 25px;
  width: 25px;

  rect {
    fill: rgba(255, 255, 255, 0);
    opacity: 0;
  }

  path {
    transition: fill 0.3s;
    fill: ${({ theme }: IconProps) => THEME[theme]};
  }

  @media screen and (max-width: 992px) {
    path {
      fill: ${({ themeMobile }: IconProps) => THEME[themeMobile]};
    }
  }
`;

export { Wrapper, Icon };
