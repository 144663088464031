import * as React from 'react';
import semver from 'semver';

import Text from 'components/core/Text';

import {
  Content,
  TextWrapper,
  SubTextWrapper,
  Picture,
  CloseIcon,
  BackgroundStyled,
  Controls,
  ButtonCancel,
  ButtonAccept,
  H2,
} from './styles';

function pageReload() {
  window.location.reload(true);
}

const Updates = ({ hideModal, modalData }) => {
  const changelog = modalData.data.Changelog;
  const { version } = modalData;

  return (
    <>
      <BackgroundStyled onClick={() => hideModal()} />
      <Content>
        <CloseIcon onClick={() => hideModal()} />
        <Picture />
        <TextWrapper>
          <Text variant="textSmall" weight="bold" transform="uppercase" align="center">
            There&apos;s an update available!
          </Text>
        </TextWrapper>
        <SubTextWrapper>
          <Text align="center" mb={4}>
            Here&apos;s what&apos;s changed:
          </Text>
        </SubTextWrapper>
        <SubTextWrapper>
          <Text align="center" mb={4}>
            {Object.keys(changelog)
              .filter(item => semver.gt(item, version))
              .map(item => (
                <>
                  <H2>{item}</H2>
                  {changelog[item].raw}
                </>
              ))}
          </Text>
        </SubTextWrapper>
        <Controls>
          <ButtonAccept type="button" handleClick={pageReload}>
            Update
          </ButtonAccept>
          <ButtonCancel type="button" handleClick={hideModal}>
            Cancel
          </ButtonCancel>
        </Controls>
      </Content>
    </>
  );
};

export default Updates;
