import styled from 'styled-components';
import colors from '../../../constants/colors';
import { Background, Close } from '../styles';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  padding: 80px 80px 40px;
  background-color: ${colors.main.white};
  border-radius: 2px;
  opacity: 0.99;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;

  @media screen and (max-width: 1100px) {
    padding: 16px;
  }

  @media screen and (max-width: 992px) {
    animation: easeinVertical 1s;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: none;
    padding: 50px 20px 20px;
  }
  @keyframes easeinVertical {
    from {
      transform: translateY(140%);
    }
    to {
      transform: translateY(0%);
    }
  }
`;

const CloseStyled = styled(Close)`
  top: 18px;
  right: 18px;
  height: 18px;
  width: 18px;
  @media screen and (max-width: 992px) {
    right: 19px;
    z-index: 100001;
  }
`;

const Content = styled.div`
  width: 960px;

  @media screen and (max-width: 992px) {
    width: unset;
  }
`;

const InformationContainer = styled.div`
  width: 800px;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    width: unset;
  }
`;

const AdvertisementContainer = styled.div`
  margin-top: 58px;
  display: flex;
  justify-content: ${props => (props.notAllDisplay ? 'space-evenly' : 'space-between')};

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding-bottom: 60px;
  }
`;

export { Background, Wrapper, CloseStyled, Content, InformationContainer, AdvertisementContainer };
