import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Text from 'components/core/Text';
import imageUrlGenerator from '../../../utils/imageUrlGenerator';
import { fetchPrograms } from '../../../actions/programs';
import RegulatorUpsell from './RegulatorUpsell';
import {
  Wrapper,
  Background,
  Picture,
  ButtonStyled,
  Content,
  CloseStyled,
  PictureText,
} from './styles';

const regulatorUpsellId = 6;

const PostPurchase = ({ hideModal, modalData }) => {
  const { product = {}, upsellProductId } = modalData;

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(fetchPrograms());
    hideModal();
  }, [dispatch, hideModal]);

  return (
    <>
      <Background onClick={handleClose} />
      <Wrapper>
        <CloseStyled onClick={handleClose} />
        <Content>
          {!upsellProductId && (
            <Picture
              style={{
                backgroundImage: `url(${imageUrlGenerator(product.picture)})`,
              }}
            >
              <PictureText>
                <Text variant="mainHeader" tag="div" color="white" isCondensed>
                  {product.name || ''}
                </Text>
              </PictureText>
            </Picture>
          )}
          <Text variant="sectionHeader" tag="p" color="black">
            {product.name} is yours!
          </Text>
          <Text color="gray" align="justify" mt={4}>
            Get ready to start feeling more agile and fluid in virtually everything you do, and to
            learn some fun movements and combos along the way.
          </Text>
          {upsellProductId === regulatorUpsellId && (
            <RegulatorUpsell upsellProductId={upsellProductId} hideModal={hideModal} />
          )}
          {!upsellProductId && <ButtonStyled handleClick={handleClose}>Close</ButtonStyled>}
        </Content>
      </Wrapper>
    </>
  );
};

export default memo(PostPurchase);
