import * as React from 'react';
import NextLink from 'next/link';

const Link = ({
  href,
  className = '',
  children,
  onClick,
  style,
  target,
}: {
  href: string;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  target?: string;
}) => (
  <NextLink href={href}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
    <a className={className} onClick={onClick} style={{ ...style }} target={target}>
      {children}
    </a>
  </NextLink>
);

export default Link;
