import React from 'react';

const SvgAWeekStreak20 = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="13 7 85 85" {...props}>
    <defs>
      <linearGradient
        id="a_week-streak_20_svg__b"
        x1={0.122}
        y1={0.288}
        x2={0.819}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#b7a2ff" />
        <stop offset={1} stopColor="#5c6cbf" />
      </linearGradient>
      <filter
        id="a_week-streak_20_svg__a"
        x={0}
        y={0}
        width={111}
        height={108.607}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={7} />
        <feGaussianBlur stdDeviation={10} result="blur" />
        <feFlood floodColor="#554688" floodOpacity={0.4} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Ring Chart8" fill="#c7baff">
      <path
        data-name="Path 1498"
        d="M55.502 7a42.5 42.5 0 0 1 13.133 2.08l-1.545 4.755A37.5 37.5 0 0 0 55.502 12z"
      />
      <path
        data-name="Path 1499"
        d="M68.635 9.08a42.5 42.5 0 0 1 28.193 50.341 42.5 42.5 0 0 1-47.974 32.056 42.5 42.5 0 0 1-35.721-45.312A42.5 42.5 0 0 1 55.502 7v5a37.5 37.5 0 0 0-37.384 34.558 37.5 37.5 0 0 0 31.518 39.98 37.5 37.5 0 0 0 42.33-28.284A37.5 37.5 0 0 0 67.09 13.835z"
      />
    </g>
    <g filter="url(#a_week-streak_20_svg__a)">
      <path
        data-name="Path 1497"
        d="M24.789.252L.478 17.911a1.148 1.148 0 0 0-.423 1.33l9.252 28.545a1.236 1.236 0 0 0 1.149.847h30.056a1.236 1.236 0 0 0 1.149-.847l9.253-28.544a1.148 1.148 0 0 0-.423-1.33L26.18.252a1.18 1.18 0 0 0-1.391 0z"
        transform="translate(30.02 22.97)"
        fill="url(#a_week-streak_20_svg__b)"
      />
    </g>
    <text
      data-name={20}
      transform="translate(30 40)"
      fill="#fff"
      fontSize={15}
      fontFamily="DINNextW01"
      fontWeight="700"
      letterSpacing=".04em"
    >
      <tspan x={16.86} y={14}>
        20
      </tspan>
    </text>
  </svg>
);

export default SvgAWeekStreak20;
