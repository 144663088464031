import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Achievements from '../../../components/Modal/Achievements';

// actions
import { requestFetchAchievements } from '../../../actions/achievements';

// selectors
import { getNotViewedAchievements } from '../../../reducers/achievements';

const mapStateToProps = state => {
  return {
    notViewedAchievements: getNotViewedAchievements(state.achievements),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestFetchAchievements,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Achievements);
