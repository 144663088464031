import React from 'react';

const SvgASessions1 = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="14 7 85 85" {...props}>
    <defs>
      <linearGradient
        id="a_sessions_1_svg__b"
        x1={0.317}
        y1={0.193}
        x2={0.739}
        y2={0.762}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ef73c1" />
        <stop offset={1} stopColor="#6f79ae" />
      </linearGradient>
      <filter
        id="a_sessions_1_svg__a"
        x={0}
        y={0}
        width={110}
        height={110}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={7} />
        <feGaussianBlur stdDeviation={10} result="blur" />
        <feFlood floodColor="#ac4887" floodOpacity={0.4} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Ring Chart7">
      <path
        data-name="Path 1495"
        d="M56.5 7q1.335 0 2.669.084l-.314 4.99Q57.679 12 56.5 12z"
        fill="#ffb1e2"
      />
      <path
        data-name="Path 1496"
        d="M59.169 7.084A42.5 42.5 0 0 1 98.953 51.5a42.5 42.5 0 0 1-43.788 40.479 42.5 42.5 0 0 1-41.16-43.147A42.5 42.5 0 0 1 56.5 7v5A37.5 37.5 0 0 0 19 48.911a37.5 37.5 0 0 0 36.322 38.07 37.5 37.5 0 0 0 38.636-35.715 37.5 37.5 0 0 0-35.1-39.192z"
        fill="#f0f2f5"
      />
    </g>
    <g filter="url(#a_sessions_1_svg__a)">
      <path
        data-name="Path 1482"
        d="M24.161 49.685L.36 25.863a1.161 1.161 0 0 1 0-1.68L24.161.36a1.158 1.158 0 0 1 1.679 0l23.8 23.822a1.161 1.161 0 0 1 0 1.68l-23.8 23.822a1.275 1.275 0 0 1-1.679.001z"
        transform="translate(30 23)"
        fill="url(#a_sessions_1_svg__b)"
      />
    </g>
    <text
      data-name={1}
      transform="translate(30 39)"
      fill="#f8f9fa"
      fontSize={15}
      fontFamily="DINNextW01"
      fontWeight="700"
      letterSpacing=".04em"
    >
      <tspan x={21.33} y={14}>
        1
      </tspan>
    </text>
  </svg>
);

export default SvgASessions1;
