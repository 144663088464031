import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { PonderInput } from 'components/Pages/SessionsPonder/Content/styles';
import Button from 'components/Button';
import Text from 'components/core/Text';
import { SessionWorkout } from 'reducers/workout/types';
import { EASE, QUALITY } from 'constants/ponder';
import RadioButtonPictogram from 'components/RadioButtonPictogram';
import { requestUpdateWorkoutSurvey } from 'actions/workout';
import { hideModal } from 'actions/modal';
import { StoreType } from 'reducers/types';
import { Content, Background as Overlay } from '../styles';
import { CloseIcon, InputWrapper, ModalContainer, OptionsGrid, ModalControls } from './styles';

interface Props {
  modalData: {
    workout: SessionWorkout;
  };
}

const PonderEditing: FC<Props> = ({ modalData }) => {
  const { workout } = modalData;
  const isUpdating = useSelector(
    (state: StoreType) => state.workout.sessions.items.find(w => w.id === workout.id)?.isUpdating,
  );
  const dispatch = useDispatch();
  const ponderNote = workout?.workoutSurvey?.ponder;
  const ease = workout?.workoutSurvey?.ease;
  const quality = workout?.workoutSurvey?.quality;

  const handleClose = useCallback(
    e => {
      e.preventDefault();
      dispatch(hideModal());
    },
    [dispatch],
  );

  const onSubmit = useCallback(
    values => {
      dispatch(
        requestUpdateWorkoutSurvey({
          ...workout,
          workoutSurvey: {
            ponder: values.ponder,
            ease: values.ease,
            quality: values.quality,
          },
        }),
      );
    },
    [dispatch, workout],
  );

  return (
    <>
      <Overlay onClick={handleClose} />
      <Content>
        <CloseIcon onClick={handleClose} />
        <ModalContainer>
          <Formik
            initialValues={{ ponder: ponderNote, ease, quality }}
            onSubmit={onSubmit}
            render={({ values, handleChange, handleBlur, handleSubmit }) => (
              <>
                <Text variant="displayX" marginBottom={6}>
                  Ponder
                </Text>
                <InputWrapper>
                  <PonderInput
                    name="ponder"
                    value={values.ponder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputWrapper>
                <Text variant="displayM" marginBottom={6}>
                  How do you feel?
                </Text>
                <OptionsGrid>
                  {EASE.map(ease => (
                    <RadioButtonPictogram
                      name="ease"
                      value={ease.value}
                      key={ease.value}
                      icon={ease.icon}
                      active={values.ease === ease.value}
                    >
                      <b>{ease.name}</b>
                      <br />
                      {ease.description}
                    </RadioButtonPictogram>
                  ))}
                </OptionsGrid>
                <Text variant="displayM" marginBottom={6}>
                  How did you move?
                </Text>
                <OptionsGrid>
                  {QUALITY.map(quality => (
                    <RadioButtonPictogram
                      name="quality"
                      value={quality.value}
                      key={quality.value}
                      icon={quality.icon}
                      active={values.quality === quality.value}
                    >
                      <b>{quality.name}</b>
                      <br />
                      {quality.description}
                    </RadioButtonPictogram>
                  ))}
                </OptionsGrid>
                <ModalControls>
                  <Button handleClick={handleSubmit} isLoading={isUpdating}>
                    Save
                  </Button>
                  <Button handleClick={handleClose} secondary>
                    Cancel
                  </Button>
                </ModalControls>
              </>
            )}
          />
        </ModalContainer>
      </Content>
    </>
  );
};

export default PonderEditing;
