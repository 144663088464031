import React, { useCallback, useMemo } from 'react';

import Text from 'components/core/Text';
import LinkIcon from '../../../LinkIcon';
import StartRestartResumeButton, {
  StartRestartResumeHandler,
} from '../../../StartRestartResumeButton';
import ProgramPreview from '../ProgramPreview';

import {
  FeaturedProgram,
  Program,
  InformationDay,
  Information,
  InformationTitle,
  StyledButton,
} from './styles';

const FeaturedPrograms = ({
  program: featuredProgram,
  clearExercisesState,
  lastUserWorkoutSessionDate,
  fetchPrograms,
  isResumingSession,
  showModal,
}) => {
  const { externalLink, isExternal, nextSession } = featuredProgram;

  const sessionDaysCompleted = useMemo(
    () => (featuredProgram.completedSessionsCount / featuredProgram.sessionsCount) * 100,
    [featuredProgram],
  );

  const onStartRestartResume = useCallback(
    event => {
      if (isExternal) {
        return;
      }

      const allowedTargets = [
        'information',
        'information-day',
        'information-title',
        'program-image',
      ];
      if (allowedTargets.includes(event.target.getAttribute('name'))) {
        event.preventDefault();
        const startRestartResumeHandler = StartRestartResumeHandler({
          lastUserWorkoutSessionDate,
          fetchPrograms,
          isResumingSession,
          showModal,
          program: featuredProgram,
        });
        startRestartResumeHandler(event);
      }
    },
    [
      featuredProgram,
      isExternal,
      isResumingSession,
      lastUserWorkoutSessionDate,
      fetchPrograms,
      showModal,
    ],
  );

  const renderedInformationDay = !isExternal && (
    <InformationDay name="information-day">
      <Text variant="caption" weight="medium" color="white" transform="uppercase" withShadow>
        <span>{nextSession?.name}</span>
      </Text>
    </InformationDay>
  );

  return (
    <FeaturedProgram>
      <Program
        key={featuredProgram.id}
        sessionDaysCompleted={sessionDaysCompleted}
        onClick={event => onStartRestartResume(event)}
        href={isExternal ? externalLink : `/programs/${featuredProgram.id}/sessions`}
      >
        {isExternal && <LinkIcon />}
        <ProgramPreview program={featuredProgram} />
        <Information name="information">
          {renderedInformationDay}
          <InformationTitle name="information-title">{featuredProgram.name}</InformationTitle>
          {isExternal ? (
            <StyledButton>{`visit ${featuredProgram.name}`}</StyledButton>
          ) : (
            <StartRestartResumeButton
              program={featuredProgram}
              clearExercisesState={clearExercisesState}
            />
          )}
        </Information>
      </Program>
    </FeaturedProgram>
  );
};

export default FeaturedPrograms;
