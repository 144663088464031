import styled, { css } from 'styled-components';

export const Wrapper = styled.main`
  margin: 105px auto;
  position: relative;
  width: 990px;

  @media screen and (max-width: 992px) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const WrapperContainer = styled.div`
  margin: 0 auto;
  width: 930px;

  @media screen and (max-width: 992px) {
    margin: 0 auto;
    width: 89.333%;
  }
`;

export const hideOnMobile = css`
  @media screen and (max-width: 992px) {
    display: none;
  }
`;
