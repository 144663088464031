import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideModal } from 'actions/modal';
import Section from 'components/core/Section';
import Text from 'components/core/Text';
import { StoreType } from 'reducers/types';
import imageUrlGenerator from 'utils/imageUrlGenerator';
import DefaultVideoPlayer from 'components/DefaultVideoPlayer';
import Loader from 'components/Loader';
import { Background } from '../styles';
import {
  Content,
  ExerciseModalClose,
  CuesGrid,
  CuePreview,
  CuesWrapper,
  CloseButton,
} from './styles';

const ExerciseDetails = () => {
  const dispatch = useDispatch();
  const exerciseDetails = useSelector((state: StoreType) => state.exercises.exerciseDetails);
  const exerciseDetailsLoading = useSelector(
    (state: StoreType) => state.exercises.exerciseDetailsLoading,
  );

  const onCloseHandler = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const renderedCues = useMemo(
    () =>
      exerciseDetails?.cueImages && (
        <CuesWrapper pt={6} fillViewport={false}>
          <Text variant="display" mb={5}>
            Form Cues
          </Text>
          <CuesGrid>
            {exerciseDetails?.cueImages?.map(cue => (
              <div key={cue.id}>
                <CuePreview src={imageUrlGenerator(cue.picture, '480x480', '480x480')} alt="" />
                <Text>{cue.description}</Text>
              </div>
            ))}
          </CuesGrid>
        </CuesWrapper>
      ),
    [exerciseDetails],
  );

  const renderedPlayer = useMemo(
    () => (
      <Section fillViewport={false}>
        {exerciseDetailsLoading && <Loader />}
        {exerciseDetails && exerciseDetails.fullVideo && (
          <DefaultVideoPlayer
            video={exerciseDetails.fullVideo}
            placeholderPicture={`https://image.mux.com/${exerciseDetails.fullVideo.muxPlaybackId}/thumbnail.jpg?time=0`}
          />
        )}
      </Section>
    ),
    [exerciseDetails, exerciseDetailsLoading],
  );

  const renderedExerciseData = useMemo(
    () => (
      <Section p={6} pb={8} fillViewport={false}>
        <Text variant="displayX" mb={3}>
          {exerciseDetails?.name}
        </Text>
        <Text mb={6} variant="text">
          {exerciseDetails?.description}
        </Text>
        {renderedCues}
      </Section>
    ),
    [exerciseDetails, renderedCues],
  );

  const renderedContent = useMemo(
    () =>
      exerciseDetailsLoading ? (
        <Section py={10}>
          <Loader />
        </Section>
      ) : (
        <>
          {renderedPlayer}
          {renderedExerciseData}
        </>
      ),
    [exerciseDetailsLoading, renderedExerciseData, renderedPlayer],
  );

  return (
    <>
      <Background onClick={onCloseHandler} />
      <Content key={exerciseDetails?.id}>
        <CloseButton onClick={onCloseHandler}>
          <ExerciseModalClose />
        </CloseButton>
        {renderedContent}
      </Content>
    </>
  );
};

export default ExerciseDetails;
