import styled from 'styled-components';
import colors from '../../../constants/colors';
import { Background, Close } from '../styles';
import Button from '../../Button';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  padding: 50px 50px 30px;
  background-color: ${colors.main.white};
  border-radius: 2px;
  opacity: 0.99;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;

  @media screen and (max-width: 992px) {
    animation: easeinVertical 1s;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: none;
    padding: 50px 20px 20px;
  }
  @keyframes easeinVertical {
    from {
      transform: translateY(140%);
    }
    to {
      transform: translateY(0%);
    }
  }
`;

const Content = styled.div`
  width: 600px;

  @media screen and (max-width: 992px) {
    width: unset;
  }
`;

const Picture = styled.div`
  width: 100%;
  height: 332px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 200px;
  }

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    position: absolute;
    background: linear-gradient(35deg, rgba(216, 65, 129, 0.5) 16%, rgba(14, 160, 195, 0.5) 64%);
  }
`;

const PictureText = styled.div`
  position: absolute;
  bottom: 10px;
  left: 20px;

  @media screen and (max-width: 992px) {
    margin: -5px 15px 11px 20px;
  }
`;

const ButtonStyled = styled(Button)`
  height: 40px;
  margin: 30px auto 0;
`;

const CloseStyled = styled(Close)`
  top: 18px;
  right: 18px;
  height: 18px;
  width: 18px;
  @media screen and (max-width: 992px) {
    right: 19px;
    z-index: 100001;
  }
`;

export { Background, Wrapper, Picture, ButtonStyled, Content, CloseStyled, PictureText };
