import React, { useMemo } from 'react';

import imageUrlGenerator from '../../../../utils/imageUrlGenerator';

import ProgramImage from './styles';

const DEFAULT_FEATURED_SIZE = '930x540';

const ProgramPreview = ({ program, size = DEFAULT_FEATURED_SIZE }) => {
  const { nextSessionPicture: picture } = program;
  const [width, height] = useMemo(() => size.split('x'), [size]);
  const imageUrl = useMemo(() => {
    if (picture) {
      const preview = picture.slice(picture.lastIndexOf('/') + 1);
      return imageUrlGenerator(preview, size, size);
    }

    return imageUrlGenerator(program.picture);
  }, [picture, program, size]);

  return <ProgramImage name="program-image" src={imageUrl} width={width} height={height} />;
};

export default ProgramPreview;
