import styled from 'styled-components';
import elements from '../../../../assets/icons/program/elements.svg';
import mobility from '../../../../assets/icons/program/mobility.svg';
import vitamin from '../../../../assets/icons/program/vitamin.svg';
import integralStrength from '../../../../assets/icons/program/integralStrength.svg';
import hexagon from '../../../../assets/icons/program/hexagon.svg';
import Button from '../../../Button';

const ICON_MAP = {
  elements,
  mobility,
  vitamin,
  integralStrength,
};

const ImageWrapper = styled.div`
  width: 300px;
  height: 200px;
  background: no-repeat center/cover url(${props => props.backgroundUrl});
`;

const HexagonContainer = styled.div`
  position: relative;
`;

const Hexagon = styled.svg`
  background: url(${hexagon}) 50% 50% no-repeat !important;
  width: 50px;
  height: 50px;
  z-index: 1;
`;

const Icon = styled.svg`
  background: url(${props => ICON_MAP[props.iconKey]}) 50% 50% no-repeat !important;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
`;

const InfoBlockContainer = styled.div`
  display: flex;
  padding: 8px 12px 0;
  height: 100%;
  background: linear-gradient(35deg, rgba(216, 65, 129, 0.5) 16%, rgba(14, 160, 195, 0.5) 64%);
`;

const DescriptionContainer = styled.div`
  margin-left: 12px;
`;

const ButtonContainer = styled.div`
  margin-top: 12px;
`;

const StyledButton = styled(Button)`
  height: 40px;
`;

export {
  ImageWrapper,
  HexagonContainer,
  Hexagon,
  Icon,
  InfoBlockContainer,
  DescriptionContainer,
  StyledButton,
  ButtonContainer,
};
