import styled, { css } from 'styled-components';
import colors from 'constants/colors';
import { pictograms } from '../../constants';

const Wrapper = styled.div`
  display: flex;
  margin: 1vw 2vw 1vw 0;
  flex: 1 1 0px;

  @media screen and (max-width: 992px) {
    width: 100%;
    flex: 1 0 auto;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Label = styled.label`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ececec;
  padding: 10px 20px;
  border-radius: 10px;
  opacity: 0.9;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin: auto;
  box-shadow: 1px 1px 3px -2px #000;
  border: 2px solid transparent;

  ${props =>
    props.active &&
    css`
      opacity: 1;
      background: #f3f1f1;
      box-shadow: none;
    `}

  &:hover {
    opacity: 1;
    background: #f3f1f1;
    box-shadow: none;
  }

  @media screen and (max-width: 992px) {
    flex-direction: row;
    padding: 10px;
  }
`;

const TextWrapper = styled.div`
  word-break: keep-all;

  @media screen and (max-width: 992px) {
    text-align: left;
    padding-left: 3%;
  }
`;

const Pictogram = styled.div`
  height: 80px;
  width: 160px;

  ${props =>
    props.icon
      ? css`
          background: url(${pictograms[props.icon]}) center center no-repeat;
        `
      : css`
          background: none;
        `}

  background-size: contain;
`;

const Input = styled.input`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -999999;

  &:checked + ${Label} {
    opacity: 1;
    background: #f3f1f1;
    border: 2px solid ${colors.main.lightBlue};
  }
`;

export { Wrapper, TextWrapper, Input, Label, Pictogram };
