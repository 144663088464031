import React from 'react';
import Router from 'next/router';
import Text from 'components/core/Text';
import { Wrapper, BackgroundStyled, Picture, ButtonStyled, Content } from './styles';

const Index = ({ modalData }) => {
  const handleClose = () => {
    Router.push('/');
  };

  return (
    <>
      <BackgroundStyled onClick={handleClose} />
      <Wrapper>
        <Picture />
        <Content>
          <Text variant="sectionHeader" tag="p" align="left">
            CHECKOUT ERROR
          </Text>
          <Text align="left" mt={5}>
            {modalData.error
              ? modalData.error
              : 'It seems that there is not enough money on this card. Please try another one.'}
          </Text>
          <ButtonStyled handleClick={handleClose}>OK</ButtonStyled>
        </Content>
      </Wrapper>
    </>
  );
};

export default Index;
