export const scheduleTemplate = {
  sun: { name: 'Sunday', key: 'sun', array: [] },
  mon: { name: 'Monday', key: 'mon', array: [] },
  tue: { name: 'Tuesday', key: 'tue', array: [] },
  wed: { name: 'Wednesday', key: 'wed', array: [] },
  thu: { name: 'Thursday', key: 'thu', array: [] },
  fri: { name: 'Friday', key: 'fri', array: [] },
  sat: { name: 'Saturday', key: 'sat', array: [] },
};

export const scheduleOrder = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export const scheduleObject = {
  sun: false,
  mon: false,
  tue: false,
  wed: false,
  thu: false,
  fri: false,
  sat: false,
};
