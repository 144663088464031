import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AlphaPost from '../../../components/Modal/AlphaPost';
import { showModal } from '../../../actions/modal';
// selectors
import { getNotViewedAchievements } from '../../../reducers/achievements';

const mapStateToProps = state => {
  return {
    notViewedAchievements: getNotViewedAchievements(state.achievements),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AlphaPost);
