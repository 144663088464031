import React from 'react';

const SvgASessions10 = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="14 7 85 85" {...props}>
    <defs>
      <linearGradient
        id="a_sessions_10_svg__b"
        x1={0.317}
        y1={0.193}
        x2={0.739}
        y2={0.762}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ef73c1" />
        <stop offset={1} stopColor="#6f79ae" />
      </linearGradient>
      <filter
        id="a_sessions_10_svg__a"
        x={0}
        y={0}
        width={110}
        height={110}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={7} />
        <feGaussianBlur stdDeviation={10} result="blur" />
        <feFlood floodColor="#ac4887" floodOpacity={0.4} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Ring Chart5">
      <path
        data-name="Path 1491"
        d="M56.502 7a42.5 42.5 0 0 1 13.133 2.08l-1.545 4.755A37.5 37.5 0 0 0 56.502 12z"
        fill="#ffb1e2"
      />
      <path
        data-name="Path 1492"
        d="M69.635 9.08a42.5 42.5 0 0 1 28.193 50.341 42.5 42.5 0 0 1-47.974 32.056 42.5 42.5 0 0 1-35.721-45.312A42.5 42.5 0 0 1 56.502 7v5a37.5 37.5 0 0 0-37.384 34.558 37.5 37.5 0 0 0 31.518 39.98 37.5 37.5 0 0 0 42.33-28.284A37.5 37.5 0 0 0 68.09 13.835z"
        fill="#f0f2f5"
      />
    </g>
    <g filter="url(#a_sessions_10_svg__a)">
      <path
        data-name="Path 1482"
        d="M24.161 49.685L.36 25.863a1.161 1.161 0 0 1 0-1.68L24.161.36a1.158 1.158 0 0 1 1.679 0l23.8 23.822a1.161 1.161 0 0 1 0 1.68l-23.8 23.822a1.275 1.275 0 0 1-1.679.001z"
        transform="translate(30 23)"
        fill="url(#a_sessions_10_svg__b)"
      />
    </g>
    <text
      data-name={10}
      transform="translate(30 39)"
      fill="#f8f9fa"
      fontSize={15}
      fontFamily="DINNextW01"
      fontWeight="700"
      letterSpacing=".04em"
    >
      <tspan x={16.86} y={14}>
        10
      </tspan>
    </text>
  </svg>
);

export default SvgASessions10;
