import styled from 'styled-components';
import Button from '../../../Button';

const DividingLine = styled.hr`
  width: 70%;
  margin: 22px auto 0;
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 28px;
  align-items: center;

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
`;

const InfoLeftBlock = styled.div`
  width: calc((100% - 20px) / 2);
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 992px) {
    width: unset;
    align-self: start;
  }
`;

const Picture = styled.div`
  width: calc((100% - 20px) / 2);
  height: 184px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 200px;
    max-width: 316px;
    margin: 0 auto;
  }

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    position: absolute;
    background: linear-gradient(35deg, rgba(216, 65, 129, 0.5) 16%, rgba(14, 160, 195, 0.5) 64%);
  }
`;

const StyledButton = styled(Button)`
  height: 40px;
  margin: 22px auto 0;
`;

export { DividingLine, InfoContainer, InfoLeftBlock, Picture, StyledButton };
