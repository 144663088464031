import styled from 'styled-components';
import colors from '../../../constants/colors';
import { Background } from '../styles';
import PictureSrc from './il_payment_error.svg';
import Button from '../../Button';

const BackgroundStyled = styled(Background)`
  @media screen and (max-width: 992px) {
    background-color: ${colors.main.black};
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  background-color: ${colors.main.white};
  width: 409px;
  height: 472px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10001;
  transform: translate(-50%, -50%);
  padding: 50px;

  @media screen and (max-width: 992px) {
    height: 468px;
    width: 335px;
    padding: 40px 35px 40px 35px;
  }
`;

const Picture = styled.div`
  background: url(${PictureSrc});
  width: 214px;
  height: 172px;
`;
const Content = styled.div`
  width: 309px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 992px) {
    width: 265px;
  }
`;
const ButtonStyled = styled(Button)`
  width: 100%;
  height: 60px;
  margin: 40px auto 0 auto;
  @media screen and (max-width: 992px) {
    margin-top: 37px;
    width: 100%;
  }
`;
export { BackgroundStyled, Wrapper, Picture, ButtonStyled, Content };
