import React, { memo } from 'react';
import styled from 'styled-components';
import LoaderSvg from './loader.svg';

const StyledLoader = styled.div`
  background: url(${LoaderSvg}) 50% 50% no-repeat;
  width: 70px;
  height: 70px;
  margin: 75px auto;
`;

const Loader = () => <StyledLoader />;

export default memo(Loader);
