import * as React from 'react';

import { WrapperContainer } from '../../../../../constants/globalStyles';

export default class extends React.PureComponent {
  render() {
    const { children } = this.props;
    return <WrapperContainer>{children}</WrapperContainer>;
  }
}
