import React, { memo } from 'react';
import Router from 'next/router';
import Text from 'components/core/Text';
import imageUrlGenerator from '../../../../utils/imageUrlGenerator';
import { DividingLine, InfoContainer, InfoLeftBlock, Picture, StyledButton } from './styles';

const RegulatorUpsell = ({ upsellProductId, hideModal }) => {
  return (
    <>
      <DividingLine />
      <Text color="gray" mt={7}>
        📈 For even faster progress…
      </Text>
      <Text variant="sectionHeader" tag="p" color="black" mt={4}>
        Keep Your Body Tuned-Up Between Sessions
      </Text>
      <Text color="gray" align="justify" mt={4}>
        The better you recover after training, resist injuries, and stay attuned to your body’s
        needs the better your results will be. That’s why we made <strong>Regulator</strong> with
        our favorite practices to keep you moving and feeling your best.
      </Text>
      <InfoContainer>
        <InfoLeftBlock>
          <Text variant="textSmall" color="gray" align="justify">
            <strong>Recovery</strong> – light movement to loosen up and reduce soreness between
            workouts
          </Text>
          <Text variant="textSmall" color="gray" align="justify">
            <strong>Resilience</strong> – protect your joints so you don’t get sidelined by common
            injuries
          </Text>
          <Text variant="textSmall" color="gray" align="justify">
            <strong>Respiration</strong> – reduce stress and train more efficiently by mastering
            your breath
          </Text>
        </InfoLeftBlock>
        <Picture
          style={{
            backgroundImage: `url(${imageUrlGenerator(
              `38d0cf02-a0c7-46e2-8bc6-4159c3cdd7ee.jpg`,
              '740x470',
            )})`,
          }}
        />
      </InfoContainer>
      <Text color="gray" align="justify" mt={5}>
        Includes 18 quick routines that are infinitely repeatable to support your training for years
        to come.
      </Text>
      <StyledButton
        handleClick={() => {
          hideModal();
          Router.push(`/products/buy?productId=${upsellProductId}&upsell`);
        }}
      >
        $49 – Add Regulator Now{' '}
      </StyledButton>
      <Text variant="textSmall" color="gray" align="justify" mt={5}>
        Regulator will be available to purchase later, but the normal price is 53% more.
      </Text>
    </>
  );
};

export default memo(RegulatorUpsell);
