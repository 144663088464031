// @flow
import React from 'react';
import { Field } from 'formik';

import Text from 'components/core/Text';
import { Wrapper, Label, Pictogram, Input, TextWrapper } from './styles';

export default function RadioButtonPictogram(props) {
  const { name, children, value, icon, onChange, active, onClick } = props;

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <Wrapper>
            <Input
              id={`radio-${name}-${value}`}
              type="radio"
              checked={field.value === value}
              onChange={event => {
                form.setFieldValue(name, value);
                if (onChange) {
                  onChange(event);
                }
              }}
              onClick={event => {
                if (onClick) {
                  onClick(event);
                }
              }}
            />
            <Label htmlFor={`radio-${name}-${value}`} active={active}>
              <Pictogram icon={icon} />
              <TextWrapper>
                <Text color="black" align="center" mt={1}>
                  {children}
                </Text>
              </TextWrapper>
            </Label>
          </Wrapper>
        );
      }}
    </Field>
  );
}
