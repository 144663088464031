import * as React from 'react';

import Text from 'components/core/Text';

import {
  Content,
  TextWrapper,
  SubTextWrapper,
  Picture,
  CloseIcon,
  BackgroundStyled,
} from './styles';

const Index = ({ hideModal, modalData }) => {
  setTimeout(() => {
    // hideModal();
  }, 2500);

  return (
    <>
      <BackgroundStyled onClick={() => hideModal()} />
      <Content>
        <CloseIcon onClick={() => hideModal()} />
        <Picture />
        <TextWrapper>
          <Text variant="sectionHeader" tag="p" transform="uppercase" align="center">
            {modalData.title}
          </Text>
        </TextWrapper>
        <SubTextWrapper>
          <Text align="center" mb={4}>
            {modalData.description}
          </Text>
        </SubTextWrapper>
      </Content>
    </>
  );
};

export default Index;
