import * as React from 'react';
import Router from 'next/router';

import Text from 'components/core/Text';

import { Content, TextWrapper, Picture, CloseIcon, BackgroundStyled } from './styles';

const Index = ({ hideModal }) => {
  function handleCloseAndRoute() {
    Router.push('/auth').then(() => {
      hideModal();
    });
  }

  setTimeout(() => {
    handleCloseAndRoute();
  }, 2500);

  return (
    <>
      <BackgroundStyled onClick={handleCloseAndRoute} />
      <Content>
        <CloseIcon onClick={handleCloseAndRoute} />
        <Picture />
        <TextWrapper>
          <Text
            variant="sectionHeader"
            tag="p"
            weight="normal"
            transform="uppercase"
            align="center"
          >
            Your password was successfully set!
          </Text>
        </TextWrapper>
      </Content>
    </>
  );
};

export default Index;
