import React, { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import Reset from './Reset';
import CommonSuccess from './CommonSuccess';
import ProgramDemo from './ProgramDemo';
import PaymentError from './PaymentError';
import PostPurchase from './PostPurchase';
import LeavingPage from './LeavingPage';
import SuccessFeedback from './SuccessFeedback';
import CompleteRegistaration from './CompleteRegistaration';
import AlphaPost from '../../containers/Modal/AlphaPost';
import Achievements from '../../containers/Modal/Achievements';
import ProfileEditing from '../../containers/Modal/ProfileEditing';
import SessionCompleted from '../../containers/Modal/SessionCompleted';
import FPsFramework from '../../containers/Modal/FPsFramework';
import Updates from '../../containers/Modal/Updates';
import ProgramCompleted from './ProgramCompleted';
import MotivationMessage from './MotivationMessage';
import ChangeProgramSchedule from './ChangeProgramSchedule';
import ExerciseDetails from './ExerciseDetails';
import { Wrapper, Content } from './styles';
import PonderEditing from './PonderEditing';

const MODAL_COMPONENTS = {
  MODAL_RESET: Reset,
  COMMON_SUCCESS: CommonSuccess,
  PROGRAM_DEMO: ProgramDemo,
  PAYMENT_ERROR: PaymentError,
  POST_PURCHASE: PostPurchase,
  LEAVING_PAGE: LeavingPage,
  SUCCESS_FEEDBACK: SuccessFeedback,
  ALPHA_POST: AlphaPost,
  ACHIEVEMENTS: Achievements,
  PONDER_EDITING: PonderEditing,
  PROFILE_EDITING: ProfileEditing,
  COMPLETE_REGISTARATION: CompleteRegistaration,
  SESSION_COMPLETED: SessionCompleted,
  FPS_FRAMEWORK: FPsFramework,
  EXERCISE_DETAILS: ExerciseDetails,
  SHOW_AVAILABLE_UPDATES: Updates,
  PROGRAM_COMPLETED: ProgramCompleted,
  SHOW_MOTIVATION_MESSAGE: MotivationMessage,
  CHANGE_PROGRAM_SCHEDULE: ChangeProgramSchedule,
};

const Modal = ({ modalType, modalData, hideModal }) => {
  const SpecificModal = useMemo(() => MODAL_COMPONENTS[modalType], [modalType]);
  const router = useRouter();

  const handleHideModal = useCallback((event, modalType, hideModal) => {
    if (event.keyCode === 27 && modalType) {
      if (modalType !== 'PAYMENT_ERROR') {
        hideModal();
      }
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = event => {
      handleHideModal(event, modalType, hideModal);
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleHideModal, hideModal, modalType]);

  const handleHideModalOnNavigation = useCallback((modalType, hideModal) => {
    if (modalType) {
      hideModal();
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      handleHideModalOnNavigation(modalType, hideModal);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [handleHideModalOnNavigation, hideModal, modalType, router.events]);

  if (!modalType) {
    return null;
  }

  return (
    <Wrapper>
      <Content>
        <SpecificModal hideModal={hideModal} modalData={modalData} />
      </Content>
      <style jsx global>
        {`
          body {
            overflow: hidden;
          }
        `}
      </style>
    </Wrapper>
  );
};

export default Modal;
