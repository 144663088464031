import moment from 'moment';

import { DAYS_OF_WEEK } from '../constants';

const Index = (schedule, lastSessionDate) => {
  const lastSessionWasToday = moment().isSame(lastSessionDate, 'day');

  const todayDate = moment().format('ddd');
  const todayDayNumber = DAYS_OF_WEEK[todayDate.toLowerCase()];

  if (!schedule.length) return null;

  const nextSession = schedule.reduce((acc, day) => {
    if (acc) return acc;

    const scheduleDayNumber = DAYS_OF_WEEK[day.toLowerCase()];

    if (!lastSessionWasToday && scheduleDayNumber === todayDayNumber) {
      return moment().format('dddd, MMM DD');
    }
    if (scheduleDayNumber > todayDayNumber) {
      return moment()
        .day(scheduleDayNumber)
        .format('dddd, MMM DD');
    }
    return acc;
  }, null);

  const day = DAYS_OF_WEEK[schedule[0]] + 7;

  return (
    nextSession ||
    moment()
      .day(day)
      .format('dddd, MMM DD')
  );
};

export default Index;
