import React, { memo } from 'react';
import MuxPlayer from '@mux/mux-player-react';
// eslint-disable-next-line import/no-unresolved
import '@mux/mux-player/themes/minimal';
import imageUrlGenerator from '../../utils/imageUrlGenerator';
import { PlaceholderPicture } from './styles';
import colors from '../../constants/colors';

const DefaultVideoPlayer = ({ video, placeholderPicture }) => {
  return (
    <>
      {!video ? (
        <PlaceholderPicture
          style={{
            backgroundImage: `url(${imageUrlGenerator(placeholderPicture)})`,
          }}
        />
      ) : (
        <MuxPlayer
          theme="minimal"
          accentColor={colors.main.lightBlue}
          playbackId={video.muxPlaybackId}
          metadata={{
            title: video.title,
            id: video.id,
          }}
        />
      )}
    </>
  );
};

export default memo(DefaultVideoPlayer);
