import styled from 'styled-components';
import colors from '../../../constants/colors';

import ProgramActivity from '../../ProgramActivity';
import Link from '../../Link';

import { Close, Background } from '../styles';

const BackgroundStyled = styled(Background)``;

const Wrapper = styled.main`
  background: ${colors.main.dividers};
  position: relative;
  margin: 105px auto;
  width: 990px;
  opacity: 0.99;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;
  z-index: 10002;

  & > * {
    cursor: default;
    pointer-events: none;
  }

  @media screen and (max-width: 992px) {
    animation: easeinVertical 1s;
    margin: 0 auto;
    margin-top: 50px;
    width: 100%;
  }

  @keyframes easeinVertical {
    from {
      transform: translateY(140%);
    }
    to {
      transform: translateY(0%);
    }
  }
`;

const Section = styled.section`
  background: ${colors.main.white};
  border-radius: 2px;
  position: relative;
  padding-top: 40px;
  padding-bottom: 30px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 992px) {
    padding-top: 0;
    padding-bottom: 40px;
    overflow: hidden;

    &:not(:first-of-type) {
      padding-top: 40px;
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

// activity sessions and programs count
const ProgramsActivity = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ProgramsActivityCount = styled.div`
  display: flex;
  margin-bottom: 12px;
  width: 100%;
`;

const StyledProgramActivity = styled(ProgramActivity)`
  width: calc(50% - 15px);

  &:nth-of-type(odd) {
    margin-right: 15px;
  }

  &:nth-of-type(even) {
    margin-left: 15px;
  }

  @media screen and (max-width: 992px) {
    &:nth-of-type(odd),
    &:nth-of-type(even) {
      margin: 0;
    }

    width: 100%;
  }
`;

const ActivitiesLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledClose = styled(Close)`
  height: 16px;
  width: 16px;
  top: -16px;
  right: -16px;
  transform: translate(100%, -100%);

  &:before,
  &:after {
    background: ${colors.main.white};
  }

  @media screen and (max-width: 1100px) {
    right: 45px;
  }

  @media screen and (max-width: 992px) {
    transform: translateY(-100%);
    top: -15px;
    right: 10px;

    &:before,
    &:after {
      background: ${colors.main.black};
    }
  }
`;

export {
  Wrapper,
  Section,
  SectionHeader,
  ProgramsActivity,
  ProgramsActivityCount,
  StyledProgramActivity,
  ActivitiesLink,
  StyledClose,
  BackgroundStyled,
};
