import colors from 'constants/colors';
import styled from 'styled-components';

import icCloseBlack from '../../../assets/icons/ic_close_black.svg';

export const CloseIcon = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 32px;
  background: url(${icCloseBlack});
  right: 32px;
  margin: 15px 15px 0 0;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  margin-bottom: 16px;
`;

export const ModalContainer = styled.div`
  background-color: #fff;
  width: calc(100vw - 32px);
  padding: 64px;
  max-width: 990px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 32px;
  }
`;

export const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-bottom: 64px;

  & > div {
    margin: 0;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ModalControls = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;
  gap: 16px;
  border-top: 1px solid ${colors.main.dividers};

  & > * {
    width: max-content;
    padding: 8px 32px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
`;
