/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import moment from 'moment';
import dateOfNextSession from './dateOfNextSession';

export default programs => {
  programs?.forEach(item => {
    const { lastSession, schedule, createdAt } = item;
    const latestSessionDate = lastSession?.createdAt;
    let nextSessionDate = null;

    // if program is scheduled, calculate nextSessionDate
    // making sure user didn't already complete a session today
    if (schedule) {
      const filteredSchedule = Object.keys(schedule).filter(key => schedule[key]);
      nextSessionDate = dateOfNextSession(filteredSchedule, latestSessionDate);
    }

    item.programGrantDate = moment(createdAt); // always exists
    item.latestSessionDate = latestSessionDate ? moment(latestSessionDate) : null;
    item.nextSessionDate = nextSessionDate
      ? moment(nextSessionDate, 'ddd, MMM DD').set({ year: moment().year() })
      : null;
  });

  programs = programs?.sort((a, b) => {
    // external program go last
    if (a.isExternal) {
      return 1;
    }
    if (b.isExternal) {
      return -1;
    }

    // if a is not scheduled, but b is, sort b before a
    if (!a.nextSessionDate && b.nextSessionDate) {
      return 1;
    }
    // if a is scheduled, but b is not, sort a before b
    if (a.nextSessionDate && !b.nextSessionDate) {
      return -1;
    }

    // if neither program is scheduled OR both programs have same scheduled date,
    // pick a program with date in the past that's closest to today
    if (a.nextSessionDate == b.nextSessionDate) {
      return (
        Math.max(b.programGrantDate, b.latestSessionDate) -
        Math.max(a.programGrantDate, a.latestSessionDate)
      );
    }
    // otherwise, return program with scheduled date closest to today
    return a.nextSessionDate - b.nextSessionDate;
  });
  return programs;
};
