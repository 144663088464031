import React from 'react';
import Text from 'components/core/Text';
import ProgramPreview from '../ProgramPreview';
import LinkIcon from '../../../LinkIcon';

import {
  OtherPrograms,
  CustomSimpleBar,
  OtherProgram,
  Information,
  InformationTitle,
  SessionInformation,
} from './styles';

const ProgramsList = ({ programs }) => {
  const renderOtherProgram = program => {
    const {
      id: programId,
      nextSession,
      completedSessionsCount,
      sessionsCount,
      isExternal,
      externalLink,
    } = program;
    const sessionDaysCompleted = (completedSessionsCount / sessionsCount) * 100;
    let linkHref;

    if (isExternal) {
      linkHref = externalLink;
    } else {
      linkHref = `/programs/${programId}/sessions/${nextSession?.id}`;
      if (!nextSession?.id || program.completedSessionsCount === 0) {
        linkHref = `/programs/${programId}`;
      }
    }

    return (
      <OtherProgram key={programId} sessionDaysCompleted={sessionDaysCompleted} href={linkHref}>
        {isExternal && <LinkIcon isSmall />}
        <ProgramPreview program={program} size="280x162" />
        <Information>
          {!isExternal && (
            <SessionInformation>
              <Text variant="caption" tag="h4" weight="medium" color="white" mr={4} withShadow>
                <span>{nextSession?.name}</span>
              </Text>
            </SessionInformation>
          )}
          <InformationTitle name="information-title">{program.name}</InformationTitle>
        </Information>
      </OtherProgram>
    );
  };

  if (!programs.length) {
    return null;
  }

  return (
    <OtherPrograms>
      <CustomSimpleBar>{programs.map(item => renderOtherProgram(item))}</CustomSimpleBar>
    </OtherPrograms>
  );
};

export default ProgramsList;
