import styled from 'styled-components';
import colors from '../../../constants/colors';
import { Background } from '../styles';

const BackgroundStyled = styled(Background)`
  @media screen and (max-width: 992px) {
    background-color: ${colors.main.black};
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  background-color: ${colors.main.white};
  width: 535px;
  height: 297px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10001;
  transform: translate(-50%, -50%);
  padding: 50px;

  @media screen and (max-width: 992px) {
    height: 292px;
    width: 335px;
    padding: 40px 35px 40px 35px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 992px) {
    width: 265px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  @media screen and (max-width: 992px) {
    margin-top: 19px;
  }
`;

const Button = styled.button`
  width: 120px;
  height: 60px;
  cursor: pointer;
  background: none;
`;

const ButtonCancel = styled(Button)`
  color: ${colors.main.blue};
  margin-right: 19px;
  transition: all 0.5s;

  &:hover {
    background-color: ${colors.main.textButtonHoverBackground};
  }

  @media screen and (min-width: 1100px) {
    &:hover {
      color: ${colors.main.darkBlue};
    }
  }

  &:active {
    color: ${colors.main.black};
  }
`;

const ButtonDelete = styled(Button)`
  color: ${colors.main.ruby};
  transition: all 0.5s;

  @media screen and (min-width: 1100px) {
    &:hover {
      color: ${colors.main.darkRuby};
    }
  }

  &:active {
    color: ${colors.main.black};
  }

  &:hover {
    background-color: ${colors.main.textButtonHoverBackground};
  }
`;

export { BackgroundStyled, Wrapper, Content, Buttons, ButtonCancel, ButtonDelete };
