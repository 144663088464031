import * as React from 'react';
import { FPS_VIDEO } from '../../../constants';
import DefaultVideoPlayer from '../../DefaultVideoPlayer';
import { Wrapper, CloseIcon, Content, BackgroundStyled } from './styles';

export default class extends React.Component {
  handleCloseFPsFramework = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  render() {
    return (
      <Wrapper>
        <BackgroundStyled onClick={this.handleCloseFPsFramework} />
        <Content>
          <CloseIcon onClick={this.handleCloseFPsFramework} />
          <DefaultVideoPlayer video={FPS_VIDEO} />
        </Content>
      </Wrapper>
    );
  }
}
