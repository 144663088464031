import React from 'react';

const SvgAProfileComplited = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="10 0 85 85" {...props}>
    <defs>
      <linearGradient
        id="a_profile_complited_svg__b"
        x1={0.817}
        y1={0.891}
        x2={0.16}
        y2={0.165}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0ea7c3" />
        <stop offset={1} stopColor="#0ec392" />
      </linearGradient>
      <filter
        id="a_profile_complited_svg__a"
        x={0}
        y={0}
        width={105}
        height={105}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={10} result="blur" />
        <feFlood floodColor="#0eabbc" floodOpacity={0.4} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#a_profile_complited_svg__a)">
      <circle
        data-name="Ellipse 873"
        cx={22.5}
        cy={22.5}
        r={22.5}
        transform="translate(30 20)"
        fill="url(#a_profile_complited_svg__b)"
      />
    </g>
    <g
      data-name="Ellipse 871"
      transform="translate(10)"
      fill="none"
      stroke="#71e2c5"
      strokeWidth={2}
    >
      <circle cx={42.5} cy={42.5} r={42.5} stroke="none" />
      <circle cx={42.5} cy={42.5} r={41.5} />
    </g>
    <g
      data-name="Ellipse 872"
      transform="translate(15 5)"
      fill="none"
      stroke="#71e2c5"
      strokeWidth={2}
    >
      <circle cx={37.5} cy={37.5} r={37.5} stroke="none" />
      <circle cx={37.5} cy={37.5} r={36.5} />
    </g>
    <g data-name="Group 2840" fill="none">
      <g data-name="Ellipse 819" transform="translate(47.764 32)" stroke="#fff" strokeWidth={2}>
        <ellipse cx={4.736} cy={4.82} rx={4.736} ry={4.82} stroke="none" />
        <ellipse cx={4.736} cy={4.82} rx={3.736} ry={3.82} />
      </g>
      <g data-name="Path 1371">
        <path d="M52.5 42.565c-5.581 0-9.5 3.013-9.5 7.23V51h19v-1.205c0-4.217-3.919-7.23-9.5-7.23z" />
        <path
          d="M52.5 44.565c-2.259 0-4.258.576-5.63 1.623-.72.55-1.557 1.459-1.8 2.812h14.86c-.243-1.353-1.08-2.262-1.8-2.812-1.372-1.047-3.371-1.623-5.63-1.623m0-2c5.581 0 9.5 3.013 9.5 7.23V51H43v-1.205c0-4.217 3.919-7.23 9.5-7.23z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default SvgAProfileComplited;
