import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Text from 'components/core/Text';
import { updateSchedule } from 'actions/programs';
import { scheduleTemplate, scheduleObject } from '../../../constants/schedule';
import imageUrlGenerator from '../../../utils/imageUrlGenerator';
import {
  Background,
  Wrapper,
  CloseStyled,
  ItemsRow,
  Item,
  ButtonContainer,
  StyledButton,
  DeleteButton,
  Title,
} from './styles';

const ChangeProgramSchedule = ({ hideModal, modalData }) => {
  const [innerData, changeInnerData] = useState([]);

  useEffect(() => {
    const result = [];
    modalData.availableProgram.forEach(program => {
      result.push({ ...program, selected: false });
    });
    modalData.currentProgram.forEach(program => {
      result.push({ ...program, selected: true });
    });
    changeInnerData(result);
  }, [modalData.availableProgram, modalData.currentProgram]);

  const handleToggle = useCallback(id => {
    changeInnerData(prev =>
      prev.map(i => {
        if (i.id === id) {
          return { ...i, selected: !i.selected };
        }
        return i;
      }),
    );
  }, []);

  const { currentList, availableList } = useMemo(() => {
    const currentListRaw = [];
    const availableListRaw = [];

    innerData.forEach(i => {
      if (i.selected) {
        currentListRaw.push(i);
      } else {
        availableListRaw.push(i);
      }
    });
    return { currentList: currentListRaw, availableList: availableListRaw };
  }, [innerData]);

  const dispatch = useDispatch();
  const handleSave = useCallback(() => {
    const programList = innerData.map(i => {
      const previousSchedule = i.schedule ? i.schedule : { ...scheduleObject };
      return {
        ...i,
        schedule: { ...previousSchedule, [modalData.rowKey]: i.selected },
      };
    });

    dispatch(updateSchedule({ programs: programList }));
    hideModal();
  }, [modalData, hideModal, innerData, dispatch]);

  return (
    <>
      <Background onClick={hideModal} />
      <Wrapper>
        <CloseStyled onClick={hideModal} />
        <Text variant="displayM" weight="normal" color="black">
          {scheduleTemplate[modalData.rowKey].name}
        </Text>
        <Text variant="sectionHeader" tag="p" weight="normal" color="black" mt={2}>
          Scheduled
        </Text>
        <ItemsRow>
          {currentList.map(i => (
            <Item
              key={i.id}
              src={imageUrlGenerator(i.picture, '120x72')}
              onClick={() => handleToggle(i.id)}
            >
              <DeleteButton />
              <Title>
                <Text tag="span" weight="bold" color="white">
                  {i.name}
                </Text>
              </Title>
            </Item>
          ))}
          {currentList.length === 0 && (
            <Text tag="div" color="black">
              None
            </Text>
          )}
        </ItemsRow>
        <Text variant="sectionHeader" tag="p" weight="normal" color="black" mt={7}>
          Available
        </Text>
        <ItemsRow>
          {availableList.map(i => (
            <Item
              key={i.id}
              src={imageUrlGenerator(i.picture, '120x72')}
              onClick={() => handleToggle(i.id)}
            >
              <Title>
                <Text tag="span" weight="bold" color="white">
                  {i.name}
                </Text>
              </Title>
            </Item>
          ))}
          {availableList.length === 0 && (
            <Text tag="div" color="black">
              None
            </Text>
          )}
        </ItemsRow>
        <ButtonContainer>
          <StyledButton handleClick={handleSave}>save</StyledButton>
        </ButtonContainer>
      </Wrapper>
    </>
  );
};

export default memo(ChangeProgramSchedule);
