import styled from 'styled-components';

const ProgramImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export default ProgramImage;
