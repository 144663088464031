import React from 'react';

const SvgAdSessions25 = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="14 7 85 85" {...props}>
    <defs>
      <linearGradient
        id="ad_sessions_25_svg__b"
        x1={0.317}
        y1={0.193}
        x2={0.739}
        y2={0.762}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#dadee3" />
        <stop offset={1} stopColor="#b6bec9" />
      </linearGradient>
      <filter
        id="ad_sessions_25_svg__a"
        x={0}
        y={0}
        width={110}
        height={110}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={7} />
        <feGaussianBlur stdDeviation={10} result="blur" />
        <feFlood floodColor="#6c7888" floodOpacity={0.4} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <path
      data-name="Path 1489"
      d="M56.504 7a42.5 42.5 0 0 1 29.093 11.519l-3.423 3.645A37.5 37.5 0 0 0 56.504 12z"
      fill="#dadee3"
    />
    <path
      data-name="Path 1490"
      d="M85.597 18.519a42.5 42.5 0 0 1 6.791 53.754 42.5 42.5 0 0 1-51.529 16.743 42.5 42.5 0 0 1-26.1-47.479A42.5 42.5 0 0 1 56.504 7v5a37.5 37.5 0 0 0-36.836 30.473 37.5 37.5 0 0 0 23.036 41.894 37.5 37.5 0 0 0 45.462-14.773 37.5 37.5 0 0 0-5.992-47.43z"
      fill="#f0f2f5"
    />
    <g filter="url(#ad_sessions_25_svg__a)">
      <path
        data-name="Path 1482"
        d="M24.161 49.685L.36 25.863a1.161 1.161 0 0 1 0-1.68L24.161.36a1.158 1.158 0 0 1 1.679 0l23.8 23.822a1.161 1.161 0 0 1 0 1.68l-23.8 23.822a1.275 1.275 0 0 1-1.679.001z"
        transform="translate(30 23)"
        fill="url(#ad_sessions_25_svg__b)"
      />
    </g>
    <text
      data-name={25}
      transform="translate(30 39)"
      fill="#f8f9fa"
      fontSize={15}
      fontFamily="DINNextW01"
      fontWeight="700"
      letterSpacing=".04em"
    >
      <tspan x={16.86} y={14}>
        25
      </tspan>
    </text>
  </svg>
);

export default SvgAdSessions25;
