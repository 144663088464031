import * as React from 'react';

import Text from 'components/core/Text';

import {
  Wrapper,
  Section,
  StyledClose,
  SectionHeader,
  ProgramsActivity,
  ProgramsActivityCount,
  StyledProgramActivity,
  ActivitiesLink,
  BackgroundStyled,
} from './styles';

import Container from './components/Container';

import PurchasedPrograms from '../../PurchasedPrograms';

import { PROGRAM_DEMO_FOR_USER } from '../../../constants';

export default class extends React.Component {
  componentWillUnmount() {
    const { hideModal } = this.props;
    hideModal();
  }

  render() {
    const { hideModal } = this.props;

    return (
      <>
        <BackgroundStyled onClick={hideModal} />
        <Wrapper>
          <StyledClose onClick={hideModal} />
          <Section>
            <Container>
              <PurchasedPrograms programs={PROGRAM_DEMO_FOR_USER.programs} />
            </Container>
          </Section>
          <Section>
            <Container>
              <SectionHeader>
                <Text variant="sectionHeader" transform="uppercase" tag="h3" mb={1}>
                  RECENT ACTIVITY
                </Text>
                <ActivitiesLink href="#">
                  <Text variant="textSmall" weight="bold" transform="uppercase" color="blue">
                    more
                  </Text>
                </ActivitiesLink>
              </SectionHeader>
              <ProgramsActivityCount>
                <Text variant="captionSmall" tag="div" transform="none" color="grayLight" mr={4}>
                  63 SESSIONS
                </Text>
                <Text variant="captionSmall" tag="div" transform="none" color="grayLight">
                  3 PROGRAMS
                </Text>
              </ProgramsActivityCount>
              <ProgramsActivity>
                {PROGRAM_DEMO_FOR_USER.activities.map((activity, id) => {
                  if (!activity.workoutSurvey) return null;

                  return <StyledProgramActivity key={String(id)} activity={activity} />;
                })}
              </ProgramsActivity>
            </Container>
          </Section>
        </Wrapper>
      </>
    );
  }
}
