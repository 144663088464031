import styled from 'styled-components';
import colors from '../../constants/colors';

const Wrapper = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  position: fixed;
`;

const Background = styled.div`
  background-color: ${colors.main.black};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  animation-name: fadein;
  animation-duration: 0.3s;
  z-index: 10;

  @media screen and (max-width: 992px) {
    background: ${colors.main.white};
    opacity: 1;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.7;
    }
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 10002;

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
  }
`;

const Close = styled.div`
  display: block;
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: 10px;
  height: 25px;
  width: 25px;
  z-index: 10;

  &:before,
  &:after {
    background: ${colors.main.black};
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    height: 2px;
    width: 100%;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export { Wrapper, Content, Close, Background };
