import styled from 'styled-components';
import colors from '../../../constants/colors';
import closeIcon from '../../../assets/icons/ic_close_white.svg';
import Button from '../../Button';
import { Close } from '../styles';

export { Background } from '../styles';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  width: 690px;
  padding: 30px;
  background-color: ${colors.main.white};
  border-radius: 2px;
  opacity: 0.99;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;

  @media screen and (max-width: 1100px) {
    padding: 16px;
  }

  @media screen and (max-width: 992px) {
    animation: easeinVertical 1s;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: none;
    padding: 20px;
  }
  @keyframes easeinVertical {
    from {
      transform: translateY(140%);
    }
    to {
      transform: translateY(0%);
    }
  }
`;

export const CloseStyled = styled(Close)`
  top: 18px;
  right: 18px;
  height: 18px;
  width: 18px;
  @media screen and (max-width: 992px) {
    right: 19px;
    z-index: 100001;
  }
`;

export const ItemsRow = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Item = styled.div`
  position: relative;
  background: url(${props => props.src}) center no-repeat;
  width: 120px;
  height: 72px;
  background-size: cover;
  cursor: pointer;
  user-select: none;

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    position: absolute;
    background: linear-gradient(35deg, rgba(216, 65, 129, 0.5) 16%, rgba(14, 160, 195, 0.5) 64%);
  }
`;

export const DeleteButton = styled.i`
  position: absolute;
  right: 0;
  top: 0;
  height: 24px;
  width: 24px;
  background: url(${closeIcon}) 0 0 no-repeat;
  background-size: contain;
  cursor: pointer;
`;

export const Title = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
`;

export const StyledButton = styled(Button)`
  width: 200px;
  height: 40px;
`;
