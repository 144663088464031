import { keyframes } from 'styled-components';

const stretchBuilder = width => {
  return keyframes`
    0% {
      width: 0;
    }

    100% {
      width: ${width}%;
    }
  `;
};

export default stretchBuilder;
