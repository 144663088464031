import styled from 'styled-components';
import 'simplebar-react/dist/simplebar.min.css'; // eslint-disable-line import/no-extraneous-dependencies

const Programs = styled.div`
  display: flex;
  gap: 12px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 22px;
  width: 100%;
  z-index: 1;

  @media screen and (max-width: 550px) {
    width: 100%;
    margin-bottom: 0;
    flex-direction: column;
    gap: 4px;
  }
`;

export default Programs;
