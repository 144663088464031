import styled from 'styled-components';
import colors from '../../../constants/colors';
import ilConfirmation from '../../../assets/icons/il_confirmation.svg';
import icCloseBlack from '../../../assets/icons/ic_close_black.svg';
import { Background } from '../styles';

export const BackgroundStyled = styled(Background)``;

export const Content = styled.div`
  background-color: ${colors.main.white};
  width: 610px;
  height: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  opacity: 0.99;
  animation: fadein 1s;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;
  z-index: 10002;
  @media screen and (max-width: 992px) {
    animation: easein 1s;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    85% {
      opacity: 0.8;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes easein {
    from {
      transform: translate(-50%, 140%);
    }
    to {
      transform: translate(-50%, -50%);
    }
  }
`;

export const TextWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  width: 344px;
`;

export const Picture = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 155px;
  margin-top: 86px;
  background: url(${ilConfirmation});
  background-size: cover;
  @media screen and (max-width: 992px) {
    margin-top: 0;
  }
`;
export const CloseIcon = styled.div`
  width: 25px;
  height: 25px;
  background: url(${icCloseBlack});
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 15px 0 0;
  cursor: pointer;
`;
