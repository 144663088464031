import * as React from 'react';

import Text from 'components/core/Text';

import {
  Content,
  TextWrapper,
  Picture,
  CloseIcon,
  SubTextWrapper,
  BackgroundStyled,
} from './styles';

const Index = ({ hideModal, modalData }) => {
  return (
    <>
      <BackgroundStyled onClick={() => hideModal()} />
      <Content>
        <CloseIcon onClick={() => hideModal()} />
        <Picture />
        <TextWrapper>
          <Text variant="sectionHeader" tag="p" align="center" transform="uppercase" mt={7}>
            {modalData.title}
          </Text>
        </TextWrapper>
        <SubTextWrapper>
          <Text align="center" mt={5}>
            {modalData.description}
          </Text>
        </SubTextWrapper>
      </Content>
    </>
  );
};

export default Index;
