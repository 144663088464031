import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SessionCompleted from '../../../components/Modal/SessionCompleted';

// actions
import { showModal } from '../../../actions/modal';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(SessionCompleted);
