import styled from 'styled-components';
import colors from '../../../constants/colors';
import thumbUp from '../../../assets/icons/thumb_up.svg';
import { Background } from '../styles';
import Button from '../../Button';

export const BackgroundStyled = styled(Background)`
  @media screen and (max-width: 992px) {
    background-color: ${colors.main.black};
    opacity: 0.7;
  }
`;

export const Wrapper = styled.div`
  background-color: ${colors.main.white};
  width: 500px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10001;
  transform: translate(-50%, -50%);
  padding: 30px;

  @media screen and (max-width: 992px) {
    width: 335px;
    padding: 20px 15px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 992px) {
    width: 265px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  @media screen and (max-width: 992px) {
    margin-top: 19px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${colors.main.blue};
  color: ${colors.main.white};
  transition: all 0.5s;
  width: 150px;

  @media screen and (max-width: 992px) {
    width: 100%;
  }

  &:hover {
    background-color: ${colors.main.lightBlue};
  }
`;

export const Picture = styled.div`
  width: 120px;
  height: 120px;
  margin: 0 auto 20px auto;
  background: url(${thumbUp});
`;

export const Bold = styled.span`
  font-weight: 700;
`;
