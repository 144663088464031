import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Router from 'next/router';
import Text from 'components/core/Text';
import {
  Background,
  Wrapper,
  CloseStyled,
  Content,
  InformationContainer,
  AdvertisementContainer,
} from './style';
import Card from './Card';

const elementsData = {
  id: 16,
  productId: 4,
  name: 'Elements',
  description: 'The Core of the Entire GMB Curriculum',
  imgKey: 'elements',
  backgroundUrl: 'fa8a9039-6309-4a17-896c-bd1fa981c729.jpg',
};

const integralStrengthFEData = {
  id: 27,
  productId: 5,
  name: 'Integral Strength: Fully Equipped',
  description: 'Build Practical Strength with Bodyweight Exercises',
  imgKey: 'integralStrength',
  backgroundUrl: 'a9eb3473-b9d7-4253-8479-c88427fd6357.jpg',
};

const hardcodedPrograms = [
  elementsData,
  integralStrengthFEData,
  {
    id: 14,
    productId: 3,
    name: 'GMB Mobility',
    description: 'Free Up Your Body to Move Easier and Perform Better',
    imgKey: 'mobility',
    backgroundUrl: '01780370-44ef-4005-86d4-091a506c8b2e.jpg',
  },
];

const ProgramCompleted = ({ hideModal, modalData: { programId } = {} }) => {
  const handleClose = useCallback(() => {
    hideModal();
  }, [hideModal]);

  const handleCloseAndRedirectToDashboard = useCallback(() => {
    hideModal();
    Router.push('/');
  }, [hideModal]);

  const userPrograms = useSelector(state => state.programs?.all);

  const { programName, sessionsCount } = useMemo(() => {
    if (!programId || !userPrograms) {
      return {};
    }

    const completedProgram = userPrograms.find(i => i.id === Number(programId)) ?? {};

    return {
      programName: completedProgram?.name,
      sessionsCount: completedProgram?.sessionsCount,
    };
  }, [userPrograms, programId]);

  const filteredHardcodedPrograms = useMemo(() => {
    if (programId === 22) {
      return [elementsData];
    }

    if (programId === 32) {
      return [integralStrengthFEData];
    }

    return hardcodedPrograms.filter(i => i.id !== Number(programId));
  }, [programId]);

  return (
    <>
      <Background onClick={handleCloseAndRedirectToDashboard} />
      <Wrapper>
        <CloseStyled onClick={handleCloseAndRedirectToDashboard} />
        <Content>
          <InformationContainer>
            <Text variant="mainHeader" align="center" color="gray" isCondensed>
              you finished {programName} 🎉
            </Text>
            <Text
              variant="display"
              weight="normal"
              transform="none"
              align="justify"
              color="black"
              mt={7}
            >
              Congrats! Give yourself a pat on the back, you finished {sessionsCount}&nbsp;Sessions
              of {programName}.
            </Text>
            <Text
              variant="display"
              weight="normal"
              transform="none"
              align="justify"
              color="black"
              mt={5}
            >
              Now that you have a solid foundation, you&apos;re ready to unlock your next level of
              performance. By focusing on what you need most - strength, flexibility, or motor
              control - you&apos;ll make significant progress in less time and open up new skills
              and capabilities.
            </Text>
          </InformationContainer>
          <AdvertisementContainer notAllDisplay={filteredHardcodedPrograms.length < 3}>
            {filteredHardcodedPrograms.map(item => {
              return <Card key={item.id} item={item} handleClose={handleClose} />;
            })}
          </AdvertisementContainer>
        </Content>
      </Wrapper>
    </>
  );
};

export default memo(ProgramCompleted);
