import { isServer } from './getEnvironment';

const KEY = 'motivation-message-date';

export function storeLastViewedDateOFMotivation() {
  const currentDate = new Date();
  localStorage.setItem(KEY, currentDate.toISOString());
}

export function shouldDisplayMotivationMessage() {
  if (!isServer() && localStorage) {
    const currentDate = new Date();
    const date = localStorage.getItem(KEY);
    const aWeekAgo = currentDate.getTime() - 7 * 24 * 60 * 60 * 1000;

    const parsedDate = date ? new Date(date) : null;
    if (!parsedDate || parsedDate.getTime() < aWeekAgo) {
      return true;
    }
  }

  return false;
}
