import React from 'react';
import Text from 'components/core/Text';
import { storeLastViewedDateOFMotivation } from '../../../utils/motivationMessages';

import { Wrapper, BackgroundStyled, Content, Footer, StyledButton, Picture, Bold } from './styles';

const Index = ({ hideModal, modalData }) => {
  const { completedNumber, sessionName } = modalData;
  const hide = () => {
    storeLastViewedDateOFMotivation();
    hideModal();
  };
  return (
    <>
      <BackgroundStyled onClick={hide} />
      <Wrapper>
        <Content>
          <Picture />
          <Text variant="sectionHeader" tag="p" weight="normal" align="center">
            You and <Bold>{completedNumber}</Bold> other people completed a session of&nbsp;
            <Bold>{sessionName}</Bold> last week 🙌 Keep it up!
          </Text>
          <Footer>
            <StyledButton type="button" handleClick={hide}>
              OK
            </StyledButton>
          </Footer>
        </Content>
      </Wrapper>
    </>
  );
};

export default Index;
