import React from 'react';
import Head from 'next/head';
import styled from 'styled-components';

import Modal from '../../containers/Modal';

interface Props {
  title?: string;
  children: React.ReactNode;
  noindex?: boolean;
}

const LayoutWrapper = styled.div`
  padding: 0 0 72px;
`;

const Layout: React.FC<Props> = ({ title, children, noindex }) => {
  return (
    <LayoutWrapper>
      <Head>
        <title>{title}</title>
        {noindex ? <meta name="robots" content="noindex" /> : ''}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="theme-color" content="#16C0E8" />
      </Head>
      {children}
      <Modal />
    </LayoutWrapper>
  );
};

export default Layout;
