import * as React from 'react';
import Router from 'next/router';

import Text from 'components/core/Text';

import {
  Content,
  TextWrapper,
  SubTextWrapper,
  Picture,
  CloseIcon,
  BackgroundStyled,
} from './styles';

const Index = ({ hideModal, modalData, showModal, notViewedAchievements }) => {
  function handleCloseAndRoute() {
    Router.push('/profile').then(() => {
      if (notViewedAchievements && notViewedAchievements.length) {
        hideModal();
        showModal('ACHIEVEMENTS');
      } else {
        hideModal();
      }
    });
  }

  setTimeout(() => {
    handleCloseAndRoute();
  }, 2500);

  return (
    <>
      <BackgroundStyled onClick={handleCloseAndRoute} />
      <Content>
        <CloseIcon onClick={handleCloseAndRoute} />
        <Picture />
        <TextWrapper>
          <Text variant="sectionHeader" tag="p" transform="uppercase" align="center">
            {modalData.title}
          </Text>
        </TextWrapper>
        <SubTextWrapper>
          <Text align="center" mt={4}>
            {modalData.description}
          </Text>
        </SubTextWrapper>
      </Content>
    </>
  );
};

export default Index;
