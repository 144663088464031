import getConfig from 'next/config';
import { isMobile } from 'react-device-detect';

const { publicRuntimeConfig } = getConfig();

function getDevicePixelRatio() {
  const hasDprProp = typeof window !== 'undefined' && typeof window.devicePixelRatio === 'number';
  return hasDprProp ? window.devicePixelRatio : 1;
}

const currentDevicePixelRatio = getDevicePixelRatio();

const Index = (
  picture = '',
  desktopSize = '930x540',
  mobileSize = '300x400',
  defaultImage = '',
) => {
  if (!picture) {
    return defaultImage;
  }

  const selectedSize = isMobile ? mobileSize : desktopSize;
  let sizeWithDevicePixelRatio = selectedSize;

  if (currentDevicePixelRatio > 1) {
    sizeWithDevicePixelRatio = selectedSize
      .split('x')
      .map(n => Number(n) * 2)
      .join('x');
  }

  return `${publicRuntimeConfig.imageFileHost}/fit-in/${sizeWithDevicePixelRatio}/${picture}`;
};

export default Index;
