import * as React from 'react';
import moment from 'moment';
import Text from 'components/core/Text';
import imageUrlGenerator from '../../utils/imageUrlGenerator';

import {
  Wrapper,
  NoLinkWrapper,
  Progress,
  Content,
  Column,
  Title,
  Details,
  DetailsTitle,
  DetailsValue,
  Header,
  SessionThumb,
  Pictogram,
  Pictograms,
  Badge,
} from './styles';

import { PONDER_EASE, PONDER_QUALITY, EASE, QUALITY } from '../../constants';

export default class extends React.PureComponent {
  render() {
    const {
      className,
      activity,
      href,
      thumb,
      noDetails = false,
      repeated = false,
      withDate = true,
    } = this.props;

    if (!activity || !activity.id) return null;

    const iconEase = EASE.find(i => i.value === activity.workoutSurvey.ease)?.icon;
    const iconQuality = QUALITY.find(i => i.value === activity.workoutSurvey.quality)?.icon;

    const WrapperType = href ? Wrapper : NoLinkWrapper;
    return (
      <WrapperType href={href} className={className} thumb={thumb}>
        <Header>
          <Text variant="captionSmall" tag="span" mr={2}>
            {activity.session.program.name}
          </Text>
          {withDate && (
            <Text variant="captionSmall" tag="span" color="grayLight">
              {moment(activity.startedAt).format("DD MMM 'YY")}
            </Text>
          )}
          {activity.session.isBasic && activity.preparationPhaseAnswer === 2 && !noDetails && (
            <Badge>Low intensity</Badge>
          )}
        </Header>
        <Title>
          <Text
            variant="textSmall"
            tag="h3"
            weight="bold"
            color="black"
            transform="uppercase"
            withEllipsis
            mb={2}
          >
            {repeated ? 'repeated' : activity.session.name}
          </Text>
        </Title>
        <Content thumb={thumb}>
          {thumb ? (
            <SessionThumb
              style={{
                backgroundImage: `url(${imageUrlGenerator(
                  activity.session.picture,
                  '200x200',
                  '100x100',
                )})`,
              }}
            />
          ) : (
            <Column />
          )}
          <Column>
            <Pictograms>
              <Progress>
                <Pictogram icon={iconEase} />
                <Text variant="captionSmall" tag="span" color="grayLight">
                  EASE: {PONDER_EASE[activity.workoutSurvey.ease]}
                </Text>
              </Progress>
              <Progress>
                <Pictogram icon={iconQuality} />
                <Text variant="captionSmall" tag="span" color="grayLight">
                  QUALITY: {PONDER_QUALITY[activity.workoutSurvey.quality]}
                </Text>
              </Progress>
            </Pictograms>
          </Column>
          {!noDetails ? (
            <Column>
              <Details thumb={thumb}>
                <DetailsValue>{activity.timeLength}</DetailsValue>
                <DetailsTitle>
                  <Text variant="captionSmall" color="grayLight">
                    MINS
                  </Text>
                </DetailsTitle>
              </Details>
              <Details>
                <DetailsValue>{activity.exercisesCount}</DetailsValue>
                <DetailsTitle>
                  <Text variant="captionSmall" color="grayLight">
                    moves
                  </Text>
                </DetailsTitle>
              </Details>
            </Column>
          ) : null}
        </Content>
      </WrapperType>
    );
  }
}
