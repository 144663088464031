import React from 'react';

import Router from 'next/router';
import Text from 'components/core/Text';
import { Wrapper, BackgroundStyled, Content, Buttons, ButtonCancel, ButtonDelete } from './styles';

const Index = ({ hideModal, modalData }) => {
  return (
    <>
      <BackgroundStyled onClick={hideModal} />
      <Wrapper>
        <Content>
          <Text variant="sectionHeader" tag="div" align="left" transform="none">
            LEAVING THIS PAGE WILL DISCARD ALL ENTERED DATA
          </Text>
          <Text align="left" mt={5}>
            Do you want to leave the page or keep editing?
          </Text>
          <Buttons>
            <ButtonCancel type="button" onClick={hideModal}>
              <Text tag="span" weight="bold">
                KEEP EDITING
              </Text>
            </ButtonCancel>
            <ButtonDelete
              type="button"
              onClick={() => {
                hideModal();
                Router.push(modalData.pathname);
              }}
            >
              <Text tag="span" weight="bold">
                LEAVE
              </Text>
            </ButtonDelete>
          </Buttons>
        </Content>
      </Wrapper>
    </>
  );
};

export default Index;
