import styled from 'styled-components';
import { rgba } from 'polished';
import colors from '../../constants/colors';

const PlaceholderPicture = styled.div`
  background-color: ${rgba(colors.main.black, 0.1)};
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 2px;
  margin: 0 auto 24px;
  height: 176px;
  width: 314px;
`;

const Video = styled.video`
  display: block;
  margin: 0 auto;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 992px) {
    height: 100% !important;
  }
`;

export { PlaceholderPicture, Video };
