import React from 'react';

const SvgATrainingLog = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="10 6 85 85" {...props}>
    <defs>
      <linearGradient
        id="a_training-log_svg__b"
        x1={0.351}
        y1={0.314}
        x2={0.817}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f57e97" />
        <stop offset={1} stopColor="#e91b61" />
      </linearGradient>
      <filter
        id="a_training-log_svg__a"
        x={0}
        y={0}
        width={105}
        height={111.603}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={7} />
        <feGaussianBlur stdDeviation={10} result="blur" />
        <feFlood floodColor="#ff7979" floodOpacity={0.4} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      data-name="Ellipse 867"
      transform="translate(10 6)"
      fill="none"
      stroke="#f8b1c0"
      strokeWidth={2}
    >
      <circle cx={42.5} cy={42.5} r={42.5} stroke="none" />
      <circle cx={42.5} cy={42.5} r={41.5} />
    </g>
    <g
      data-name="Ellipse 868"
      transform="translate(15 11)"
      fill="none"
      stroke="#f8b1c0"
      strokeWidth={2}
    >
      <circle cx={37.5} cy={37.5} r={37.5} stroke="none" />
      <circle cx={37.5} cy={37.5} r={36.5} />
    </g>
    <g filter="url(#a_training-log_svg__a)">
      <path
        data-name="Path 1530"
        d="M45 38.091V13.512a1.183 1.183 0 0 0-.611-1.039L23.111.183a1.111 1.111 0 0 0-1.223 0L.611 12.473A1.261 1.261 0 0 0 0 13.512v24.579a1.183 1.183 0 0 0 .611 1.039l21.278 12.29a1.111 1.111 0 0 0 1.223 0l21.277-12.29A1.183 1.183 0 0 0 45 38.091z"
        transform="translate(30 23)"
        fill="url(#a_training-log_svg__b)"
      />
    </g>
    <g data-name="Group 4009">
      <path
        data-name="Path 1533"
        d="M47 61v-4h-2a2.006 2.006 0 0 1-2-2V43a2.006 2.006 0 0 1 2-2h16a2.006 2.006 0 0 1 2 2v12a2.006 2.006 0 0 1-2 2h-8.7zm-2-18v12h4v2l2.7-2H61V43z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgATrainingLog;
