import React from 'react';

const SvgAdWeekStreak5 = props => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="13 7 85 85" {...props}>
    <defs>
      <linearGradient
        id="ad_week-streak_5_svg__b"
        x1={0.317}
        y1={0.193}
        x2={0.739}
        y2={0.762}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#dadee3" />
        <stop offset={1} stopColor="#b6bec9" />
      </linearGradient>
      <filter
        id="ad_week-streak_5_svg__a"
        x={0}
        y={0}
        width={111}
        height={108.607}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={7} />
        <feGaussianBlur stdDeviation={10} result="blur" />
        <feFlood floodColor="#6c7888" floodOpacity={0.4} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <path
      data-name="Path 1504"
      d="M55.5 7A42.5 42.5 0 0 1 98 49.5h-5A37.5 37.5 0 0 0 55.5 12z"
      fill="#dadee3"
    />
    <path
      data-name="Path 1505"
      d="M98 49.5A42.5 42.5 0 0 1 55.5 92 42.5 42.5 0 0 1 13 49.5 42.5 42.5 0 0 1 55.5 7v5A37.5 37.5 0 0 0 18 49.5 37.5 37.5 0 0 0 55.5 87 37.5 37.5 0 0 0 93 49.5z"
      fill="#f0f2f5"
    />
    <g filter="url(#ad_week-streak_5_svg__a)">
      <path
        data-name="Path 1497"
        d="M24.789.252L.478 17.911a1.148 1.148 0 0 0-.423 1.33l9.252 28.545a1.236 1.236 0 0 0 1.149.847h30.056a1.236 1.236 0 0 0 1.149-.847l9.253-28.544a1.148 1.148 0 0 0-.423-1.33L26.18.252a1.18 1.18 0 0 0-1.391 0z"
        transform="translate(30.02 22.98)"
        fill="url(#ad_week-streak_5_svg__b)"
      />
    </g>
    <text
      data-name={5}
      transform="translate(30 40)"
      fill="#fff"
      fontSize={15}
      fontFamily="DINNextW01"
      fontWeight="700"
      letterSpacing=".04em"
    >
      <tspan x={21.33} y={14}>
        5
      </tspan>
    </text>
  </svg>
);

export default SvgAdWeekStreak5;
