/* eslint-disable no-unused-vars */
import ASessions1 from './sessions/a_sessions_1';
import ASessions5 from './sessions/a_sessions_5';
import ASessions10 from './sessions/a_sessions_10';
import ASessions25 from './sessions/a_sessions_25';
import ASessions50 from './sessions/a_sessions_50';
import ASessions100 from './sessions/a_sessions_100';
import ASessions200 from './sessions/a_sessions_200';
import ADSessions1 from './sessions/ad_sessions_1';
import ADSessions5 from './sessions/ad_sessions_5';
import ADSessions10 from './sessions/ad_sessions_10';
import ADSessions25 from './sessions/ad_sessions_25';
import ADSessions50 from './sessions/ad_sessions_50';
import ADSessions100 from './sessions/ad_sessions_100';
import ADSessions200 from './sessions/ad_sessions_200';
import AWeekStreak1 from './week_streak/a_week_streak_1';
import AWeekStreak2 from './week_streak/a_week_streak_2';
import AWeekStreak3 from './week_streak/a_week_streak_3';
import AWeekStreak5 from './week_streak/a_week_streak_5';
import AWeekStreak10 from './week_streak/a_week_streak_10';
import AWeekStreak20 from './week_streak/a_week_streak_20';
import ADWeekStreak1 from './week_streak/ad_week_streak_1';
import ADWeekStreak2 from './week_streak/ad_week_streak_2';
import ADWeekStreak3 from './week_streak/ad_week_streak_3';
import ADWeekStreak5 from './week_streak/ad_week_streak_5';
import ADWeekStreak10 from './week_streak/ad_week_streak_10';
import ADWeekStreak20 from './week_streak/ad_week_streak_20';

import AAlphaPosse from './profile/a_alpha-posse';
import AProfileComplited from './profile/a_profile_complited';
import ATrainingLog from './profile/a_training_log';
import ADAlphaPosse from './profile/ad_alpha_posse';
import ADProfileComplited from './profile/ad_profile_complited';
import ADTrainingLog from './profile/ad_training_log';

export default {
  type_1: {
    value_1: {
      earned: ASessions1,
      normal: ADSessions1,
    },
    value_5: {
      earned: ASessions5,
      normal: ADSessions5,
    },
    value_10: {
      earned: ASessions10,
      normal: ADSessions10,
    },
    value_25: {
      earned: ASessions25,
      normal: ADSessions25,
    },
    value_50: {
      earned: ASessions50,
      normal: ADSessions50,
    },
    value_100: {
      earned: ASessions100,
      normal: ADSessions100,
    },
    value_200: {
      earned: ASessions200,
      normal: ADSessions200,
    },
  },
  type_2: {
    value_1: {
      earned: AWeekStreak1,
      normal: ADWeekStreak1,
    },
    value_2: {
      earned: AWeekStreak2,
      normal: ADWeekStreak2,
    },
    value_3: {
      earned: AWeekStreak3,
      normal: ADWeekStreak3,
    },
    value_5: {
      earned: AWeekStreak5,
      normal: ADWeekStreak5,
    },
    value_10: {
      earned: AWeekStreak10,
      normal: ADWeekStreak10,
    },
    value_20: {
      earned: AWeekStreak20,
      normal: ADWeekStreak20,
    },
  },
  type_4: {
    value_3: {
      earned: AAlphaPosse,
      normal: ADAlphaPosse,
    },
    value_1: {
      earned: ATrainingLog,
      normal: ADTrainingLog,
    },
    value_4: {
      earned: AProfileComplited,
      normal: ADProfileComplited,
    },
  },
  // 'type_7': {
  //   'value_1': {
  //     'earned': APrep1,
  //     'normal': ADPrep1
  //   },
  //   'value_5': {
  //     'earned': APrep5,
  //     'normal': ADPrep5
  //   },
  //   'value_10': {
  //     'earned': APrep10,
  //     'normal': ADPrep10
  //   },
  //   'value_25': {
  //     'earned': APrep25,
  //     'normal': ADPrep25
  //   },
  //   'value_50': {
  //     'earned': APrep50,
  //     'normal': ADPrep50
  //   },
  //   'value_100': {
  //     'earned': APrep100,
  //     'normal': ADPrep100
  //   }
  // }
};
