import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Router from 'next/router';
import Text from 'components/core/Text';
import {
  ImageWrapper,
  Hexagon,
  InfoBlockContainer,
  DescriptionContainer,
  HexagonContainer,
  Icon,
  StyledButton,
  ButtonContainer,
} from './style';
import imageUrlGenerator from '../../../../utils/imageUrlGenerator';
import StartRestartResumeButton from '../../../StartRestartResumeButton';
import { clearExercisesState } from '../../../../actions/exercise';

const Card = ({ item, handleClose }) => {
  const dispatch = useDispatch();

  const handleClear = useCallback(() => {
    dispatch(clearExercisesState());
  }, [dispatch]);

  const program = useSelector(state => state.programs?.all.find(i => i.id === item.id));

  const handleClick = useCallback(() => {
    if (!program) {
      handleClose();
      Router.push(`/products/buy?productId=${item.productId}`);
    }
  }, [program, handleClose, item.productId]);

  return (
    <div>
      <ImageWrapper backgroundUrl={imageUrlGenerator(item.backgroundUrl)}>
        <InfoBlockContainer>
          <HexagonContainer>
            <Hexagon />
            <Icon iconKey={item.imgKey} />
          </HexagonContainer>
          <DescriptionContainer>
            <Text variant="display" weight="medium" color="white" isCondensed>
              {item.name}
            </Text>
            <Text variant="textSmall" color="white" mt={2}>
              {item.description}
            </Text>
          </DescriptionContainer>
        </InfoBlockContainer>
      </ImageWrapper>
      <ButtonContainer>
        {program ? (
          <StartRestartResumeButton
            program={program}
            handleClose={handleClose}
            clearExercisesState={handleClear}
            isSmall
          />
        ) : (
          <StyledButton handleClick={handleClick}>Purchase</StyledButton>
        )}
      </ButtonContainer>
    </div>
  );
};

export default memo(Card);
