import styled, { css } from 'styled-components';
import SimpleBar from 'simplebar-react';
import { rgba } from 'polished';
import 'simplebar-react/dist/simplebar.min.css'; // eslint-disable-line import/no-extraneous-dependencies
import colors from '../../../../constants/colors';
import stretchBuilder from '../../../../utils/animations';
import Link from '../../../Link';
import Button from '../../../Button';

const Programs = styled.div`
  display: flex;
  gap: 12px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 22px;
  width: 100%;

  @media screen and (max-width: 550px) {
    width: 100%;
    margin-bottom: 0;
    flex-direction: column;
    gap: 4px;
  }
`;

const ProgramImage = styled.img`
  height: 100%;
  width: 100%;
`;

const Information = styled.div`
  align-items: flex-end;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 16px;
  bottom: 16px;
  z-index: 100;
  width: 97%;
  padding-right: 12px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 90%;
  }
`;

const SessionInformation = styled.div`
  width: 98%;
`;

// rewrite with text component
const InformationTitle = styled.h3`
  transform: translateX(-2px);
  width: 100%;
  white-space: nowrap;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.main.white};
  font-size: 48px;
  font-family: 'DINNextW01-Condensed', Arial, sans-serif;
  font-weight: 300;
  line-height: 50px;
  letter-spacing: 0;
  text-transform: uppercase;
  padding-bottom: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-shadow: 0 0 4px ${colors.main.grey};

  @media screen and (max-width: 550px) {
    white-space: normal;
  }

  @media screen and (max-width: 992px) {
    max-height: 100px;
    overflow: hidden;
  }
`;

const Program = styled(Link)`
  display: block;
  background: ${colors.main.dividers} 50% 50% no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 395px;

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    position: absolute;
    z-index: 2;
    pointer-events: none;

    ${props =>
      props.sessionDaysCompleted
        ? css`
            animation: ${stretchBuilder(props.sessionDaysCompleted)} 2s ease-in-out;
            animation-fill-mode: forwards;
            background-color: ${rgba(colors.main.lightBlue, 0.7)};
            background: linear-gradient(
              35deg,
              ${rgba(colors.main.ruby, 0.5)} 16%,
              ${rgba(colors.main.blue, 0.5)} 64%
            );
            opacity: 1;
            right: initial;
          `
        : css`
            background: linear-gradient(
              35deg,
              ${rgba(colors.main.ruby, 0.5)} 16%,
              ${rgba(colors.main.blue, 0.5)} 64%
            );
          `}
  }

  @media screen and (max-width: 992px) {
    height: 256px;
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    height: 350px;
  }
`;

const FeaturedProgram = styled.div`
  width: ${props => (props.fullWidth ? '100%' : '70%')};

  @media screen and (min-width: 550px) and (max-width: 784px) {
    padding-right: 6px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    padding-right: 0;
    border-bottom: 1px solid #f0f0f0;
  }

  ${ProgramImage} {
    filter: brightness(0.8);
    object-fit: cover;
    z-index: 1;
  }
`;

const OtherProgram = styled(Link)`
  display: block;
  background: ${colors.main.dividers} 50% 50% no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    position: absolute;
    background: linear-gradient(
      35deg,
      ${rgba(colors.main.ruby, 0.5)} 16%,
      ${rgba(colors.main.blue, 0.5)} 64%
    );
  }

  @media screen and (max-width: 550px) {
    width: 200px;
    float: left;
    height: 125px;
    flex-shrink: 0;
    margin-right: 5px;
  }

  @media screen and (min-width: 550px) {
    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const CustomSimpleBar = styled(SimpleBar)`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  flex-wrap: nowrap;
  display: flex;

  .simplebar-content-wrapper {
    padding-right: 12px;
  }

  @media screen and (max-width: 550px) {
    padding-right: 0;
    height: 138px;
    .simplebar-content {
      display: flex;
    }
  }

  @media screen and (min-width: 992px) {
    overflow-x: hidden;
  }
`;

const OtherPrograms = styled.div`
  flex: 0 0 30%;
  width: 30%;
  float: left;
  height: 395px;
  overflow-y: auto;
  overflow-x: hidden;

  ${Information} {
    bottom: 4px;
    padding-right: 0;
    span {
      max-width: 98%;
      text-transform: uppercase;
    }
  }

  ${InformationTitle} {
    font-size: 34px;
    margin-bottom: 0;
  }

  @media screen and (min-width: 550px) and (max-width: 784px) {
    ${OtherProgram} {
      margin-bottom: 4px;
    }
    ${SessionInformation} {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 550px) {
    float: none;
    width: 98%;
    height: 138px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    margin: 0 auto;

    ${InformationTitle} {
      font-size: 28px;
      line-height: 30px;
      padding-top: 2px;
    }
  }

  @media screen and (min-width: 992px) {
    ${InformationTitle} {
      line-height: 44px;
    }
  }

  @media screen and (min-width: 884px) and (max-width: 992px) {
    ${InformationTitle} {
      font-size: 32px;
      line-height: 40px;
    }
    height: 256px;
  }

  @media screen and (min-width: 784px) and (max-width: 884px) {
    ${InformationTitle} {
      font-size: 28px;
      padding-bottom: 0;
      line-height: 34px !important;
    }
    height: 256px;
  }

  @media screen and (min-width: 734px) and (max-width: 784px) {
    ${InformationTitle} {
      font-size: 26px;
      padding-bottom: 0;
      line-height: 30px;
    }
    height: 256px;
  }

  @media screen and (min-width: 550px) and (max-width: 734px) {
    ${InformationTitle} {
      font-size: 24px;
      padding-bottom: 0;
      line-height: 30px;
    }
    height: 256px;
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    height: 350px;
  }
`;

const StyledButton = styled(Button)`
  max-width: 290px;
`;

export {
  CustomSimpleBar,
  FeaturedProgram,
  Programs,
  Program,
  ProgramImage,
  Information,
  InformationTitle,
  OtherProgram,
  OtherPrograms,
  SessionInformation,
  StyledButton,
};
