import Text from 'components/core/Text';
import colors from 'constants/colors';
import Link from 'next/link';
import { FC } from 'react';
import { Session } from 'reducers/programs/types';
import styled from 'styled-components';
import imageUrlGenerator from 'utils/imageUrlGenerator';

const NextSessionImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
`;

const NextSessionContent = styled.div`
  position: relative;
`;

const NextSessionLink = styled(Text)`
  position: absolute;
  bottom: 0px;
  right: -8px;
  color: ${colors.main.blue};
  background-image: radial-gradient(
    circle at 50% 100%,
    ${colors.main.secondaryBtns} 80%,
    transparent 100%
  );
  padding: 8px 16px;
  padding-left: 24px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

const NextSessionWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${colors.main.secondaryBtns};

    ${NextSessionLink} {
      opacity: 1;
    }
  }
`;

interface NextSessionButtonProps {
  programId?: string | number;
  session?: Session;
}

const NextSessionButton: FC<NextSessionButtonProps> = ({ programId, session }) => (
  <Link href={`/programs/${programId}/sessions/${session?.id}`}>
    <NextSessionWrapper>
      <NextSessionImage src={imageUrlGenerator(session?.picture)} alt="" />
      <NextSessionContent>
        <Text variant="textSmall" transform="uppercase" weight="bold" marginBottom={1}>
          {session?.name}
        </Text>
        <Text variant="textSmall">{session?.description}</Text>
        <NextSessionLink variant="textSmall" transform="uppercase" weight="bold">
          Let&apos;s preview →
        </NextSessionLink>
      </NextSessionContent>
    </NextSessionWrapper>
  </Link>
);

export default NextSessionButton;
